import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.webp";
import { BiSearch } from "react-icons/bi";
import { Button } from "../Misc/Button";
import { getCollections, viewItems } from "../../Services/apiServices";
import debounce from "lodash.debounce";

import { Oval } from "react-loader-spinner";

export default function Header() {
  const [isToggle, setToggle] = useState(false);
  const toggleMenu = () => {
    setToggle(!isToggle);
  };

  const [searchedText, setSearchedText] = useState("");
  const [searchedCollections, setSearchedCollections] = useState();
  const [searchedProds, setSearchedProds] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      console.log("listt");
      let coll = await getCollections({
        page: 1,
        limit: 12,
        isImported: true,
        isBlocked: false,
        collectionAddress: searchedText,
      });
      let prod = await viewItems({
        page: 1,
        limit: 12,
        isImported: true,
        isBlocked: false,
        productName: searchedText,
      });
      console.log("collection list", coll, prod);
      if (
        coll &&
        coll?.results &&
        coll?.results?.length > 0 &&
        coll?.results[0]?.length > 0
      ) {
        setSearchedCollections(coll?.results[0]);
      } else {
        setSearchedCollections([]);
      }

      if (prod && prod?.results && prod?.results?.length > 0) {
        setSearchedProds(prod?.results);
      } else {
        setSearchedProds([]);
      }
      setLoader(false);

      console.log("coll and prod", coll?.results[0]?.length, prod);
    };
    if (searchedText) {
      setSearchedCollections();
      setSearchedProds();
      fetch();
    }
  }, [searchedText]);

  const handleSearch = async (e) => {
    setSearchedText(e.target.value);
  };

  const optimisedSearchHandle = useCallback(debounce(handleSearch, 300), [
    searchedText,
  ]);

  return (
    <>
      <header className="sticky-top headerClass" id="headerid">
        <div className="container">
          <nav className="navbar navbar-expand-lg py-3">
            <div className="nav_container container-fluid px-0">
              <a className="navbar-brand" href="/">
                <img alt="logo" className="img-fluid" src={Logo}></img>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span
                  className={isToggle ? "toggle_btn" : null}
                  onClick={toggleMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-list toggle_svg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="input-group header_search_bar">
                  <input
                    type="text"
                    className="form-control search_bar_input"
                    onChange={optimisedSearchHandle}
                  />
                  <div className="input-group-append">
                    <span className="form-control search_bar_icon">
                      <BiSearch />
                    </span>
                  </div>
                  {console.log("search", searchedCollections, searchedProds)}
                  <div
                    className={`search_results ${
                      searchedText !== "" ? "show" : ""
                    }`}
                  >
                    {loader &&
                    searchedCollections === undefined &&
                    searchedProds === undefined ? (
                      <Oval
                        height={35}
                        width={35}
                        color="#2f7bbd"
                        wrapperStyle={{}}
                        wrapperClass="ring-spinner"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#2f7bbd"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    ) : (
                      ""
                    )}
                    {console.log("searchedText", searchedText === "")}

                    {searchedText && (
                      <>
                        {searchedCollections &&
                          searchedCollections?.length > 0 && (
                            <div>
                              <h6>Collections</h6>
                              <ul>
                                {searchedCollections?.map((c, i) => {
                                  if (i < 3) {
                                    return (
                                      <li
                                        onClick={() => {
                                          window.location.href =
                                            "/collection-details/" +
                                            c?.collectionAddress;
                                        }}
                                      >
                                        {c.collectionName}
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          )}
                        {searchedCollections &&
                        searchedCollections?.length > 3 ? (
                          <Button
                            buttonClass="mx-auto w-100 main_btn mb-4"
                            onClick={() => {
                              window.location.href =
                                "/collections/" + searchedText;
                            }}
                          >
                            View All
                          </Button>
                        ) : (
                          searchedCollections?.length === 0 && ""
                        )}
                        {searchedProds && searchedProds?.length > 0 && (
                          <div>
                            <h6>Products</h6>
                            <ul>
                              {searchedProds?.map((p, i) => {
                                console.log("pppp", p);
                                if (i < 3) {
                                  return (
                                    <li
                                      onClick={() => {
                                        window.location.href =
                                          "/item-details/" +
                                          p?.collectionData[0]?.chainId +
                                          "/" +
                                          p?.collectionAddress +
                                          "/" +
                                          p?.productId;
                                      }}
                                    >
                                      {p.productName}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        )}{" "}
                        {searchedProds && searchedProds?.length > 3 ? (
                          <Button
                            buttonClass="main_btn  w-100 mx-auto"
                            onClick={() => {
                              window.location.href = "/items/" + searchedText;
                            }}
                          >
                            View All
                          </Button>
                        ) : (
                          searchedProds?.length === 0 && ""
                        )}
                        {!loader &&
                          searchedCollections?.length === 0 &&
                          searchedProds?.length === 0 && (
                            <span variant="lg" className="NoData">
                              No Result Found
                            </span>
                          )}
                      </>
                    )}
                  </div>
                </div>

                <ul className="navbar-nav header_ul ms-auto mb-2 mb-lg-0">
                  <li className="nav-item text-center p-2">
                    <a className="navLink" href="/collections">
                      Collections
                    </a>
                  </li>
                  <li className="nav-item text-center p-2">
                    <a className="navLink" href="/items">
                      Items
                    </a>
                  </li>
                  <li className="nav-item text-center p-2">
                    <a className="navLink" href="/contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      window.open("/RedeemPortal");
                      // window.location.href = "RedeemPortal"
                    }}
                    buttonClass="main_btn header_btn px-4"
                  >
                    Get Rewards
                  </Button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
