/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Button } from "../Components/Misc/Button";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { sendMail } from "../Services/apiServices";

function ContactUs() {
  const [fullName, setFullName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState();
  const [disabledSubscribe, setDisabledSubscribe] = useState();
  const email = [process.env.REACT_APP_EMAIL];
  const subject =
    process.env.REACT_APP_EMAIL_SUBJECT + " " + "From" + " " + fullName;
  const navigate = useNavigate();
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    setDisabledSubscribe(true);
    let regx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (fullName.trim() === "") {
      setTimeout(() => {
        toast.error("Please Enter Full-Name Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else if (mail.trim() === "") {
      setTimeout(() => {
        toast.error("Please Enter Email Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else if (message.trim() === "") {
      setTimeout(() => {
        toast.error("Please Enter Message Field");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else if (!regx.test(mail)) {
      setTimeout(() => {
        toast.error("Please Enter a Valid Email");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 800);
      return;
    } else {
      let data = { fullName, mail, message, email, subject };
      const res = await sendMail(data);
      console.log(res.statusCode);
      if (res.statusCode === 200) {
        setMail("");
        setFullName("");
        setMessage("");
        setTimeout(() => {
          toast.success("Message Sent");
          setLoader(false);
          setDisabledSubscribe(false);
          navigate("/");
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error("Mail Sending Error!");
          setLoader(false);
          setDisabledSubscribe(false);
        }, 1000);
      }
    }
  };
  return (
    <>
      <section className="Banner_section">
        <div className="container ">
          <div className="banner_container">
            <p>Ask Question</p>
            <h2>Let us hear from you directly!</h2>
          </div>
        </div>
      </section>

      <section className="hot_collection">
        {/* <div className="container text-center">
          <div className="content_heading_div">
            <h3 className="content_heading">Contact Us</h3>
            <div className="content_bar"></div>
          </div>
        </div> */}

        <div className="container mt-md-5">
          <form className="needs-validation">
            <div className="form-row row ">
              <div className="col-md-4 ms-md-auto mb-3">
                {/* <label for="validationCustom01">Full Name</label> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  disabled={disabledSubscribe}
                />
              </div>
              <div className="col-md-4 me-md-auto mb-3">
                {/* <label for="validationCustom01">Email</label> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="hello@domain.com"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                  disabled={disabledSubscribe}
                />
              </div>
              <div className="col-md-8 mx-auto mb-3 mt-3">
                <textarea
                  className="form-control"
                  placeholder="How can we help you? [Note: Please mention product details and owner details for better clarification of your query]"
                  rows="8"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  disabled={disabledSubscribe}
                ></textarea>
              </div>
              <div className="col-md-8 mx-auto mt-3 mb-3">
                <Button
                  buttonClass="main_btn mx-auto"
                  onClick={sendMessageHandler}
                  disabled={disabledSubscribe}
                  id="contact-us"
                >
                  Send Message
                  {loader ? (
                    <ColorRing
                      visible={true}
                      height="25"
                      width="25"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#e15b64",
                        "#f47e60",
                        "#f8b26a",
                        "#abbd81",
                        "#849b87",
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
