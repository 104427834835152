import React, { useEffect, useState } from "react";
import { getFaqList } from "../Services/apiServices";
import hand from "../Assets/images/Vector.png";

const Faq = () => {
  const styles = {
    border: `faq_border accordion`,
    accbtn: `accordion-button collapsed  accansbtn `,
    accansbtn: `accansbtn accordion-collapse collapse hide  `,
    qfirst: `qfirst me-2`,
    imgSize: `me-2`,
  };

  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    const fetchList = async () => {
      const res = await getFaqList();
      setFaqData(res.data);
      console.log("res", res.data);
    };
    fetchList();
  }, []);
  //   console.log("FAQ Data---->", faqData.data[0].question);
  return (
    <>
      <div>
        <section className="Banner_section">
          <div className="container ">
            <div className="banner_container">
              <h2>Frequently Asked Questions (FAQs)</h2>
            </div>
          </div>
        </section>
        <section className="hot_collection ">
          <div className="container mt-md-5">
            <div className="form-row row ">
              <div className="col-md-8 offset-md-2 ">
                <div className={styles.border} id="accordionExample">
                  {faqData.map((d, key) => {
                    return (
                      <div className="accordion-item" key={key}>
                        <h2 className="accordion-header" id={"heading-" + key}>
                          <button
                            className={styles.accbtn}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse-" + key}
                            aria-expanded="true"
                            aria-controls={"collapse-" + key}
                          >
                            <span className={styles.qfirst}>{key + 1}.</span>{" "}
                            {d.question}
                          </button>
                        </h2>
                        <div
                          id={"collapse-" + key}
                          className={styles.accansbtn}
                          aria-labelledby={"heading-" + key}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <img
                              src={hand}
                              height={18}
                              width={25}
                              className={styles.imgSize}
                            />{" "}
                            {d.answer}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Faq;
