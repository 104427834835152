import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./popup.css";
const Popup = (props) => {
  const [show, setShow] = useState(props?.show);
  const { productDescription, hideFunc } = props;

  console.log("productDescription in popup", productDescription);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
          hideFunc(false);
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="long-text"
          >
            Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{productDescription}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Popup;
