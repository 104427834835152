/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Button } from "../Misc/Button";
import Slider from "react-slick";
import walletIcon from "../../Assets/images/icons/wallet.png";
import nftIcon from "../../Assets/images/icons/selectNft.png";
import recIcon from "../../Assets/images/icons/receive.png";
import claimIcon from "../../Assets/images/icons/claim.png";
import qtyIcon from "../../Assets/images/icons/QtyIcon.png";
import coins from "../../Assets/images/icons/coins.png";
import SliderNextArrow from "../Misc/SliderNextArrow";
import SliderPrevArrow from "../Misc/SliderPrevArrow";
import bgImg from "../../Assets/images/banner.webp";
import { getCollections, viewItems } from "../../Services/apiServices";
import { PLACEHOLDER } from "../../Helpers/constant";

function LandingPage() {
  const [hotCollections, setHotCollections] = useState();
  const [recentItems, setRecentItems] = useState();
  const [loading, setLoading] = useState(false);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  var settings2 = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let coll = await getCollections({
        isHotCollection: true,
        isImported: true,
        isBlocked: false,
        page: 1,
        limit: 12,
      });
      console.log("collections", coll);
      if (coll && coll?.results?.length > 0) {
        setHotCollections(coll?.results[0]);
      } else {
        setHotCollections([]);
      }

      let products = await viewItems({
        page: 1,
        limit: 12,
        isBlocked: false,
        isImported: true,
      });
      console.log("products", products);
      if (products && products?.results?.length > 0) {
        setRecentItems(products?.results);
      } else {
        setRecentItems([]);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  return (
    <>
      <section className="HomePageSection">
        <div className="container ">
          <div className="banner_container">
            <div className="row align-items-center ">
              <div className="col-lg-6 col-12 order-lg-2">
                <div className="banner_card_div">
                  <img src={bgImg} className="img-fluid" alt="NFT Perks" />
                </div>
              </div>
              <div className="col-lg-6 col-12 order-lg-1">
                <div className="banner_text_div">
                  <h5 className="sub_heading mb-0">Now Get More</h5>
                  <h2 className="main_heading">PERKS AND BENEFITS</h2>
                  <p className="para_text">
                    NFT Perks provides a platform for collection owners to
                    reward their community. NFT holders can claim digital and
                    physical items as rewards for their loyalty and trust.
                  </p>
                  <div className="reedem_btns_div">
                    <a href="/#">
                      <Button
                        buttonClass="main_btn px-5 me-2 my-2 btn"
                        onClick={() => {
                          window.open("/RedeemPortal");
                          // window.location.href = "RedeemPortal"
                        }}
                      >
                        Get Rewards
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="featured_section">
        <div className="container">
          <ul>
            <li>
              <img src={walletIcon} alt="wallet" className="img-fluid" />
              <div>
                <h5>Connect Wallet</h5>
                <p>Sign in claim portal</p>
              </div>
            </li>
            <li>
              <img src={nftIcon} alt="select Nft" className="img-fluid" />
              <div>
                <h5>Select NFT</h5>
                <p>Choose NFT from collection</p>
              </div>
            </li>
            <li>
              <img src={claimIcon} alt="claim Item" className="img-fluid" />
              <div>
                <h5>Claim Item</h5>
                <p>Sign and submit request</p>
              </div>
            </li>
            <li>
              <img src={recIcon} alt="Recieve Item" className="img-fluid" />
              <div>
                <h5>Recieve Item</h5>
                <p>Postal or email delivery</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="hot_collection">
        <div className="container text-center">
          <div className="sectionHeading">
            <h2>Hot Collections</h2>
          </div>
          {hotCollections?.length < 1 && (
            <div className="not_avail">
              <h5>Available Soon</h5>
            </div>
          )}
          <div className="row my-5">
            <Slider {...settings}>
              {hotCollections &&
                hotCollections?.length > 0 &&
                hotCollections.map((data, index) => (
                  <div className=" p-3" key={index}>
                    <div
                      className="custom_card"
                      onClick={() => {
                        window.location.href = `/collection-details/${data?.collectionAddress}`;
                      }}
                    >
                      <div className="collection_img">
                        <img
                          src={
                            data?.low_banner ? data?.low_banner : PLACEHOLDER
                          }
                          alt="collection"
                        />
                      </div>
                      <div className="profile_img">
                        <img
                          src={data?.low_image ? data?.low_image : data?.image}
                          alt="Avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="d-flex align-items-center">
                          <img src={qtyIcon} alt="Qty" />
                          <p>{data?.totalProducts} </p>
                        </div>
                        <img src={coins} alt="Qty" />
                      </div>
                      <h5 className="CollecNameHeading">
                        {data.collectionName}
                      </h5>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          {hotCollections?.length > 3 && (
            <Button
              buttonClass="main_btn px-5"
              id="viewAllCollections"
              type="button"
              onClick={() => {
                window.location.href = "/collections";
              }}
            >
              View All
            </Button>
          )}
          <div className=""></div>
        </div>
      </section>

      <section className="featured_text">
        <div className="container">
          <h3 className="text-center">
            Why just flip, when you can get more <br />
            There are lot of digital and physical items that are claimable as
            reward.
            <br /> To see what your NFT is eligible for.Visit our Rewards
            Portal.
          </h3>
        </div>
      </section>

      <section className="recent_items">
        <div className="container text-center">
          <div className="sectionHeading">
            <h2>Recent Items</h2>
          </div>
          {recentItems?.length < 1 && (
            <div className="not_avail">
              <h5>Available Soon</h5>
            </div>
          )}
          <div className="row my-5 recent_slider">
            <Slider {...settings2}>
              {recentItems &&
                recentItems?.length > 0 &&
                recentItems.map((data, index) => (
                  <div className="p-3" key={index}>
                    <div
                      className="custom_card"
                      onClick={() => {
                        window.location.href = `/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`;
                      }}
                    >
                      <div className="collection_img">
                        <img
                          src={data?.low_image ? data?.low_image : PLACEHOLDER}
                          onError={(e) => {
                            e.target.src = coins;
                          }}
                          alt="collection"
                        />
                        <p>#{data.productId}</p>
                      </div>
                      <div className="content" title={data.productName}>
                        <h5>
                          {data.productName?.length > 15
                            ? data?.productName.slice(0, 15) + "..."
                            : data.productName}
                        </h5>
                        <p title={data?.productDesc}>
                          {data?.productDesc?.length > 20
                            ? data?.productDesc.slice(0, 20) + "..."
                            : data.productDesc}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          {recentItems?.length > 4 && (
            <Button
              id="viewAllNfts"
              type="button"
              buttonClass="main_btn px-5"
              onClick={() => {
                window.location.href = "/items";
              }}
            >
              View All
            </Button>
          )}
          <div className=""></div>
        </div>
      </section>
    </>
  );
}

export default LandingPage;
