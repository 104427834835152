/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { account } from "../../Config/localStorageNames";
import { Logout } from "../../Helpers/setterFunctions";
import Modal from "../Authentication/connectWallet/Modal/Modal";
import { Button } from "../Misc/Button";
import RewardsBanner from "../Misc/rewardsBanner";
// import { Button } from '../../components/misc/button';
// import RewardsBanner from '../../components/misc/rewardsBanner';

function LandingPage() {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<RewardsBanner Heading_titile="Rewards Portal" />
			{showModal && <Modal />}
			<section className="hot_collection">
				<div className="container text-center">
					<div className="content_heading_div">
						{!sessionStorage.getItem(account) ? (
							<h3 className="content_heading">Connect Wallet To Claim Rewards</h3>
						) : (
							<p className="content_para">{sessionStorage.getItem(account)}</p>
							
						)}
						<div className="content_bar"></div>
					</div>
					<Button
						buttonClass="main_btn px-5"
						onClick={(e) => {
							e.preventDefault();
							sessionStorage.getItem(account) ? Logout() : setShowModal(true);
						}}>
						{sessionStorage.getItem(account) ? "Logout" : "Connect Wallet"}
					</Button>
					<div className="container my-5">
						<Button
							buttonClass="main_btn px-5"
							disable={!sessionStorage.getItem(account)}
							onClick={(e) => {
								e.preventDefault();
								window.location.href = "/RedeemPortal/redeem"
							}}
							>
							Start Journey
						</Button>
					</div>
				</div>
			</section>
		</>
	);
}

export default LandingPage;
