import { toast } from "react-hot-toast";

export const handleCopyToClipboard = async copyMe => {
    console.log("copy me")
    try {
        await navigator.clipboard.writeText(copyMe);
        toast.success('Copied!');
    } catch (err) {
        console.log("err", err)
        toast.error('Failed to copy!');
    }
};