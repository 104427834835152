/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Button } from "../Components/Misc/Button";
import Slider from "react-slick";
import qtyIcon from "../Assets/images/icons/QtyIcon.png";
import coins from "../Assets/images/icons/coins.png";
import SliderNextArrow from "../Components/Misc/SliderNextArrow";
import SliderPrevArrow from "../Components/Misc/SliderPrevArrow";
import RewardsBanner from "../Components/Misc/rewardsBanner";
import recentSlider from "../Assets/images/recent.png";
import { addNotifyEmail, getCollections, sendRedeemRequest } from "../Services/apiServices";
import { PAGE_LIMIT } from "../Helpers/constant";
import { account } from "../Config/localStorageNames";
import { getUserTokens } from "../Helpers/getterFunctions";
import Spinner from "../Components/Common/Spinner";
import eventEmitter from "../events/events";
import x from "../Assets/images/icons/x.png";
import { getCurrentProvider } from "../Components/Authentication/connectWallet";
import { messages, validationMessage } from "../Config/messages";
import { ethers } from "ethers";
import Items from "./items";
import { toast } from "react-hot-toast";
import PercentLoader from "../Components/Common/PercentLoader";
import Loader from "../Components/Common/loader";
import Popup from "../../WebPortal/Components/Misc/popup";
import { checkIfTokenOwnedByAdd } from "../Helpers/getterFunctions";
import { getNetworkNameByChain } from "../Config/rpcs";

function Redeem() {
	var settings1 = {
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		prevArrow: <SliderPrevArrow />,
		nextArrow: <SliderNextArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	var settings2 = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		autoPlay: true,
		slidesToScroll: 1,
		initialSlide: 1,
		prevArrow: <SliderPrevArrow />,
		nextArrow: <SliderNextArrow />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const [tokens, setTokens] = useState([]);
	const [collections, setCollections] = useState([]);
	const [collLoader, setCollLoader] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [tokenLoader, setTokenLoader] = useState(false);
	const [itemLoader, setItemLoader] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState();
	const [selectedToken, setSelectedToken] = useState();
	const [tokenInfo, setTokenInfo] = useState([]);
	const [showTokens, setShowTokens] = useState(false);
	const [showItems, setShowItems] = useState(false);
	const [step, setStep] = useState(1);
	const [selectedItem, setSelectedItem] = useState();
	const [message, setMessage] = useState("");
	const [showPercent, setShowPercent] = useState(false);
	const [status, setStatus] = useState("");
	const [color, setColor] = useState("");
	const [showMore, setShowMore] = useState(false);
	const [requestData, setRequestData] = useState({
		username: "",
		email: "",
		phone: "",
		notes: "",
		city: "",
		location: "",
		floor: "",
		state: "",
		country: "",
		postalCode: "",
	});
	const [loader, setLoader] = useState(false);
	const [notify, setNotify] = useState(false);
	const [notifyEmail, setNotifyEmail] = useState("");
	const [required, setRequired] = useState(false);
	const [ProductDesc, setProductDesc] = useState("");
	const [show, setShow] = useState(false);
	const [validating, setValidating] = useState(false);
	const handleClick = (e) => {
		var p = e.target.parentElement;
		var index = Array.prototype.indexOf.call(p.children, e.target);
		console.log(p, index);
	};

	useEffect(() => {
		if (!sessionStorage.getItem(account)) {
			window.location.href = "/RedeeemPortal";
		}
	}, []);

	useEffect(() => {
		const fetch = async () => {
			try {
				setCollLoader(true);
				let coll = await getCollections({
					page: 1,
					limit: PAGE_LIMIT,
					walletAddress: sessionStorage.getItem(account),
				});
				if (coll?.result?.length > 0) {
					setCollections(coll?.result);
				} else setCollections([]);
				setCollLoader(false);
			} catch (err) {
				console.log("err", err);
			}
		};
		fetch();
	}, [sessionStorage?.getItem(account)]);

	useEffect(() => {
		const fetch = async () => {
			setTokenLoader(true);
			let tokens = await getUserTokens(
				selectedCollection?.collectionAddress,
				sessionStorage.getItem(account),
				selectedCollection?.chainId
			);
			console.log("tokens", tokens);
			setTokens(tokens?.tokenIds);
			setTokenInfo(tokens?.basicTokenInfo);
			setTokenLoader(false);
		};
		if (selectedCollection && showTokens) {
			fetch();
		}
	}, [showTokens]);

	eventEmitter.removeAllListeners("loader_message");
	eventEmitter.on("loader_message", (msg, value) => {
		if (msg === "msg") {
			setMessage(value);
			setShowPercent(false);
		}
		if (msg === "percent") {
			setShowPercent(true);
			setMessage(value);
		}
	});

	function SelectCollectionSection() {
		return (
			<>
				<section className="hot_collection">
					<div className="container text-center">
						{collLoader && <Spinner />}
						{collections?.length === 0 && !collLoader && (
							<p>Your wallet doesn't contain any NFT from our supported collections</p>
						)}

						{/* Or if collection exist */}
						{/* <p>Or</p> */}
						<div className="row">
							<Slider {...settings1}>
								{collections &&
									collections?.length > 0 &&
									collections?.map((data, index) => (
										<div
											className="col-md-4 col-sm-6 col-12 mx-auto p-3"
											key={index}
											onClick={(e) => {
												e.preventDefault();
												if (data?.collectionAddress !== selectedCollection?.collectionAddress) {
													setShowTokens(false);
													setShowItems(false);
													setTokens([]);
													setTokenInfo([]);
													setSelectedToken(false);
												}
												setSelectedCollection(data);
											}}>
											<div
												className={
													selectedCollection?.collectionAddress === data?.collectionAddress
														? "custom_card selected"
														: "custom_card"
												}
												onClick={(e) => {
													e.preventDefault();
													handleClick(e);
												}}>
												<div className="collection_img">
													<img
														src={data?.banner}
														alt="collection"
													/>
												</div>
												<div className="profile_img">
													<img
														src={data?.image}
														alt="Avatar"
													/>
												</div>
												<div className="content">
													<div className="d-flex align-items-center">
														<img
															src={qtyIcon}
															alt="Qty"
														/>
														<p>{data?.totalProducts} </p>
													</div>
													<img
														src={coins}
														alt="Qty"
													/>
												</div>
												<h5 className="CollecNameHeading">{data.collectionName}</h5>
											</div>
										</div>
									))}
							</Slider>
						</div>
						{!collLoader && (
							<Button
								buttonClass="main_btn px-5"
								disable={selectedCollection === undefined}
								onClick={(e) => {
									e.preventDefault();
									setStep(2);
									setShowTokens(true);
								}}>
								Next
							</Button>
						)}
					</div>
				</section>
			</>
		);
	}

	function SelectNftSection() {
		return (
			<>
				<section className="recent_items">
					<div className="container text-center">
						{!showPercent && tokenLoader && (
							<div className="d-flex flex-column align-items-center jusitfy-content-center">
								<Spinner />
								<p className="mt-5">{message}</p>
							</div>
						)}
						{showPercent && (
							<div className="d-flex flex-column align-items-center jusitfy-content-center">
								<h6>Fetching NFTs data</h6>
								<PercentLoader />
								<p>{message}%</p>
							</div>
						)}

						{tokens?.length === 0 && !tokenLoader && <p>No Token Found​.</p>}

						<div className="row">
							<Slider {...settings2}>
								{tokens &&
									tokens?.length > 0 &&
									tokens.map((data, index) => (
										<div
											className="p-3"
											key={index}
											onClick={() => {
												if (data !== selectedToken) {
													setShowItems(false);
												}
												setSelectedToken(data);
											}}>
											<div
												className={selectedToken === data ? "custom_card selected" : "custom_card"}>
												<div className="collection_img">
													<img
														src={
															tokenInfo[index]?.image !== undefined
																? tokenInfo[index]?.image
																: recentSlider
														}
														alt="collection"
														onerror={recentSlider}
													/>
													<p>#{tokens[index]}</p>
												</div>
												<div className="content">
													<h5>
														{tokenInfo[index]?.name !== undefined
															? tokenInfo[index]?.name
															: "No Name"}
													</h5>
													{/* <p>{tokenInfo[index]?.description}</p> */}
												</div>
											</div>
										</div>
									))}
							</Slider>
							<div />
							<div className="container button_group mt-3">
								{showTokens && !tokenLoader && (
									<Button
										buttonClass="main_btn px-5 mx-4 my-1"
										onClick={(e) => {
											e.preventDefault();
											setStep(1);
											setShowItems(false);
											setShowTokens(false);
											setSelectedToken(false);
											setTokens([]);
										}}>
										Back
									</Button>
								)}

								{showTokens && !tokenLoader && (
									<Button
										buttonClass="main_btn px-5 mx-4 my-1"
										disable={selectedToken === undefined || !selectedToken}
										onClick={(e) => {
											e.preventDefault();
											setStep(3);
											setShowItems(true);
										}}>
										Next
									</Button>
								)}
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}

	function ItemDetailSection() {
		const hideModal = (data) => {
			setShow(data);
		};
		return (
			<>
				<section className="recent_items">
					<div className="container text-center">
						{tokenLoader && <Spinner />}
						{tokens?.length === 0 && !tokenLoader && <p>No Item Found​.</p>}
						{show && (
							<Popup
								productDescription={ProductDesc}
								show={show}
								hideFunc={hideModal}
							/>
						)}

						<div className="container item-container item_details_div">
							{/* <div className="row"><a href={`/redeem/${location?.state?.collection}`}>Back</a></div> */}
							<div className="row gy-3 item-row align-items-center">
								<div className="col-lg-6">
									<div className="item_detail_img_div d-flex justify-content-center align-items-center">
										<img
											className="item-img"
											alt="itemImage"
											src={selectedItem?.image_url}
										/>
									</div>
								</div>
								<div className="col-lg-6 detail-container">
									<div className="w-100 d-flex justify-content-end align-items-center">
										{/* {status !== "" && ( */}
										<>
											<div
												className="statusColor"
												style={{ background: color }}></div>
											<p className="statusText">{status}</p>
										</>
										{/* )} */}
									</div>
									<div className="upper_half">
										<h3 className="product-name text-start">
											{/* {location?.state?.productName} */}
											{selectedItem?.name}
										</h3>
										{ProductDesc.length > 80 ? (
											<p id="long-text">
												{ProductDesc.substring(0, 81) + "..."}{" "}
												<button
													type="submit"
													onClick={() => {
														setShow(true);
													}}>
													Read More
												</button>
											</p>
										) : (
											<p>{ProductDesc}</p>
										)}
									</div>
									<div className="lower_half mt-3">
										<h3 className="product-name">Details</h3>
										<div className="w-100 d-flex justify-content-between">
											<p className="left">Product Id </p>
											<p className="right"># {selectedItem.product_id}</p>
										</div>
										<div className="w-100 d-flex justify-content-between">
											<p className="left">Collection Name </p>
											<p className="right">{selectedCollection?.collectionName}</p>
										</div>
										<div className="w-100 d-flex justify-content-between">
											<p className="left">Collection Address</p>
											<p className="right">
												{selectedItem?.contract_address?.slice(0, 4) +
													"..." +
													selectedItem?.contract_address?.slice(38, 42)}
											</p>
										</div>
										{/* <div className="w-100 d-flex justify-content-between">
                                        <p className="left">Product Id </p>
                                        <p className="right">#R-154631</p>
                                    </div> */}
									</div>
									
									<div className="col-12 notes_div">
									<div className="w-100 d-flex justify-content-start align-items-center my-3">
										<div className="notes"></div>
										<h3 className="product-name">Notes</h3>
									</div>
									<p>
										{!showMore ? (
											<p className="text-start">
												{selectedItem?.notes
													? selectedItem?.notes?.slice(0, 120) +
													  (selectedItem?.notes?.length > 120 ? "..." : "")
													: "-"}
											</p>
										) : (
											<p className="text-start">
												{" "}
												{selectedItem?.notes ? selectedItem?.notes : "-"}
											</p>
										)}
										{selectedItem?.notes?.length > 120 ? (
											<a className="showmore"
												onClick={() => {
													setShowMore(!showMore);
												}}>
												{!showMore ? "Show More" : "Show Less"}
											</a>
										) : (
											""
										)}
									</p>
								   </div>
								   <div className="my-4 d-flex justify-content-center">
										{status === "Active" ? (
											selectedItem?.status === "available" ? (
												<Button
													buttonClass="main_btn px-5"
													onClick={async () => {
														setOpenForm(true);
													}}>
													Claim
												</Button>
											) : selectedItem?.status === "pending" ? (
												<Button
													buttonClass="main_btn px-5"
													disable={true}>
													Request Sent
												</Button>
											) : selectedItem?.status === "redeemed" ? (
												<Button
													buttonClass="main_btn px-5"
													disable={true}>
													Redeemed
												</Button>
											) : (
												""
											)
										) : status === "Upcoming" ? (
											<Button
												buttonClass="main_btn px-5"
												onClick={() => {
													setNotify(true);
												}}>
												Notify
											</Button>
										) : (
											""
										)}
									</div>
								</div>
								
							</div>
						</div>
						<div className="my-4">
							{showTokens && !tokenLoader && (
								<Button
									buttonClass="main_btn px-5 mx-4 my-1"
									disabled={tokenLoader || itemLoader}
									onClick={(e) => {
										e.preventDefault();
										setStep(3);
									}}>
									Back
								</Button>
							)}
						</div>
					</div>
				</section>
			</>
		);
	}

	const handleButtonClick = (step, data) => {
		if (step === 2) {
			setStep(2);
		}
		if (step === 4) {
			setStep(4);
			setSelectedItem(data);
			setProductDesc(data.description);
			if (
				(data?.start_date,
				data?.end_date,
				Number(data?.start_date) < Math.round(new Date().valueOf() / 1000) &&
					Number(data?.end_date) > Math.round(new Date().valueOf() / 1000))
			) {
				setStatus("Active");
				setColor("#3BE211");
			} else if (
				Number(data?.start_date) < Math.round(new Date().valueOf() / 1000) &&
				Number(data?.end_date) < Math.round(new Date().valueOf() / 1000)
			) {
				setStatus("Expired");
				setColor("#8A959E");
			} else if (Number(data?.start_date) > Math.round(new Date().valueOf() / 1000)) {
				setStatus("Upcoming");
				setColor("#E2B411");
			} else {
				setStatus("");
			}
		}
	};

	const handleInputChange = (e) => {
		setRequestData({
			...requestData,
			[e.target.id]: e.target.value,
		});
	};

	const sign = async () => {
		try {
			let provider = await getCurrentProvider();
			// await provider.send("eth_requestAccounts", []);
			const signer = provider.getSigner();
			let rawMessage = messages.signMessage;
			const signature = await signer.signMessage(rawMessage);
			const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
			return signature;
		} catch (e) {
			if (e.code === "ACTION_REJECTED") {
				setLoader(false);
				setRequestData({
					username: "",
					email: "",
					phone: "",
					notes: "",
					city: "",
					location: "",
					floor: "",
					state: "",
					country: "",
					postalCode: "",
				});
				toast.error("User Denied Signature");
				return false;
			}
		}
	};

	const handleSendRequest = async () => {
		setValidating(true);
		const ifOwner = await checkIfTokenOwnedByAdd(
			selectedItem?.contract_address,
			sessionStorage.getItem(account),
			Number(selectedItem?.chain_id),
			Number(selectedItem?.token_id)
		);

		if (!ifOwner) {
			setValidating(false);
			toast.error("You don't own this item anymore");
			return;
		}
		if (requestData?.username === "" || requestData.username?.trim() === "") {
			setValidating(false);
			toast.error(validationMessage("Username", "Enter"));
			return;
		}

		if (requestData?.email === "" || requestData.email?.trim() === "") {
			setValidating(false);
			toast.error(validationMessage("Email", "Enter"));
			return;
		}
		var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		if (!validRegex?.test(requestData.email)) {
			setValidating(false);
			toast.error("Please Enter a Valid Email");
			return;
		}

		if (
			selectedItem?.product_type === "Physical" &&
			(requestData?.phone === "" || requestData?.phone?.trim() === "")
		) {
			setValidating(false);
			toast.error(validationMessage("Phone No.", "Enter"));
			return;
		}
		if (requestData?.notes === "" || requestData?.notes?.trim() === "") {
			setValidating(false);
			toast.error(validationMessage("Notes", "Enter"));
			return;
		}

		if (selectedItem?.product_type === "Physical") {
			if (requestData.location === "" || requestData.location?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("Location", "Enter"));
				return;
			}
			if (requestData.floor === "" || requestData.floor?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("Floor Details", "Enter"));
				return;
			}
			if (requestData.city === "" || requestData.city?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("City", "Enter"));
				return;
			}

			if (requestData.state === "" || requestData.state?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("State", "Enter"));
				return;
			}
			if (requestData.postalCode === "" || requestData.postalCode?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("Postal Code", "Enter"));
				return;
			}
			if (requestData.country === "" || requestData.country?.trim() === "") {
				setValidating(false);
				toast.error(validationMessage("Country", "Enter"));
				return;
			}
		}
		setValidating(false);
		setLoader(true);
		setOpenForm(false);
		let signature = await sign();
		if (signature === false) {
			return;
		}

		const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
		if (messageSender?.toLowerCase() !== sessionStorage?.getItem(account)?.toLowerCase()) {
			return;
		}

		let address =
			requestData?.location +
			requestData?.floor +
			requestData?.city +
			requestData?.state +
			requestData?.country;
		let reqData = {
			collectionAddress: selectedCollection?.collectionAddress,
			tokenId: selectedToken,
			walletAddress: sessionStorage.getItem(account),
			productId: selectedItem?.product_id,
			chainId: selectedCollection?.chainId,
			userName: requestData.username,
			pinCode: requestData.postalCode,
			address: address,
			signature: signature,
			message: messages.signMessage,
			email: requestData?.email,
			notes: requestData?.notes,
			phoneNo: requestData?.phone,
		};
		const res = await sendRedeemRequest(reqData);
		if (res === 409) {
			setLoader(false);
			setRequestData({
				username: "",
				email: "",
				phone: "",
				notes: "",
				city: "",
				location: "",
				floor: "",
				state: "",
				country: "",
				postalCode: "",
			});
			toast.error("Request Already Exist");
			return;
		}
		if (res[0]?.status === "success") {
			setLoader(false);
			setRequestData({
				username: "",
				email: "",
				phone: "",
				notes: "",
				city: "",
				location: "",
				floor: "",
				state: "",
				country: "",
				postalCode: "",
			});
			toast.success("Request sent successfully");
			window.location.reload();
			return;
		} else {
			setLoader(false);
			setRequestData({
				username: "",
				email: "",
				phone: "",
				notes: "",
				city: "",
				location: "",
				floor: "",
				state: "",
				country: "",
				postalCode: "",
			});
			toast.error("Error while sending redemption request");
			return;
		}
	};

	const handleNotifyClick = async () => {
		console.log("notifyEmail");
		if (notifyEmail === "" || notifyEmail.trim() === "") {
			setRequired(true);
			setNotifyEmail("");
			return;
		} else {
			var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
			if (!validRegex?.test(notifyEmail)) {
				toast.error("Please Enter a Valid Email");
				return;
			}
			setNotify(false);
			setRequired(false);
			const res = await addNotifyEmail({
				collectionAddress: selectedItem?.contract_address,
				productId: Number(selectedItem.product_id),
				email: notifyEmail,
				productData: selectedItem,
			});

			if (res.status === "success") {
				setNotifyEmail("");
				toast.success(messages.emailAddedToNotifySuccess);
				return;
			} else {
				setNotifyEmail("");
				toast.error(messages.emailAddedToNotifyError);
				return;
			}
		}
	};

	return (
		<>
			<RewardsBanner Heading_titile="Rewards Portal" />
			<div className="select_sections">
				<div className="container text-center">
					<div className="selected_options_div">
						<ul className="list-unstyled d-flex flex-wrap w-100 justify-content-between">
							<li className={`${step > 1 ? "selected_optionsDisabled" : "selected_optionsAbled"}`}>
								<div className="selected_options">
									<span>Select Collection</span>
								</div>
							</li>
							<li className={`${step > 2 ? "selected_optionsDisabled" : "selected_optionsAbled"}`}>
								<div className={`${step >= 2 ? "selected_options" : "unselected_options"}`}>
									<span>Select NFT</span>
								</div>
							</li>
							<li className={`${step > 3 ? "selected_optionsDisabled" : "selected_optionsAbled"}`}>
								<div className={`${step >= 3 ? "selected_options" : "unselected_options"}`}>
									<span>Select Item</span>
								</div>
							</li>
							<li>
								<div className={`${step >= 4 ? "selected_options" : "unselected_options"}`}>
									<span>Item details</span>
								</div>
							</li>
						</ul>
						<div className="boundry"></div>
					</div>
				</div>

				{loader && <Loader />}
				{step === 1 && <SelectCollectionSection />}
				{step === 2 && <SelectNftSection />}
				{step === 3 && (
					<Items
						showItems={showItems}
						selectedCollection={selectedCollection}
						selectedToken={selectedToken}
						handleButtonClick={handleButtonClick}
					/>
				)}
				{step === 4 && <ItemDetailSection />}
			</div>
			{openForm && (
				<div className="redeem_request_popup">
					<div className="container">
						<div className="popup_card">
							<div className="popup_heading_div">
								<h3>Redeem Request</h3>
								<img
									className="img-fluid x_png"
									alt="X"
									src={x}
									onClick={() => {
										setOpenForm(false);
										setRequestData({
											username: "",
											email: "",
											phone: "",
											notes: "",
											city: "",
											location: "",
											floor: "",
											state: "",
											country: "",
											postalCode: "",
										});
									}}
									style={{
										cursor: "pointer",
									}}
								/>
							</div>
							<div className="popup_content_div">
								<div className="row">
									<div className="col-md-6 mx-auto">
										<div className="content_div">
											<form>
												<div className="form-group">
													<label
														for="username"
														className="required">
														Name
													</label>
													<input
														type="text"
														className="form-control"
														id="username"
														placeholder="Name"
														value={requestData?.username}
														onChange={(e) => handleInputChange(e)}
														maxLength={25}
													/>
												</div>
												<div className="form-group">
													<label
														for="email"
														className="required">
														Email
													</label>
													<input
														type="email"
														className="form-control"
														id="email"
														placeholder="Email"
														value={requestData?.email}
														onChange={(e) => handleInputChange(e)}
														maxLength={50}
													/>
												</div>
												{selectedItem?.product_type === "Physical" && (
													<div className="form-group">
														<label
															for="phone"
															className="required">
															Phone No.
														</label>
														<input
															type="text"
															className="form-control"
															id="phone"
															placeholder="Phone No."
															value={requestData?.phone}
															onKeyPress={(e) => {
																if (!/^\d*$/.test(e.key)) e.preventDefault();
															}}
															onChange={(e) => {
																console.log(
																	"e.target.value",
																	e.target.value,
																	isNaN(e.target.value)
																);
																if (isNaN(e.target.value)) {
																	return;
																}
																handleInputChange(e);
															}}
															maxLength={15}
														/>
													</div>
												)}
												<div className="form-group">
													<label
														for="notes"
														className="required">
														Notes
													</label>
													<textarea
														className="form-control"
														id="notes"
														rows="5"
														value={requestData?.notes}
														onChange={(e) => handleInputChange(e)}
														maxLength={300}></textarea>
												</div>
											</form>
										</div>
									</div>
									{selectedItem?.product_type === "Physical" && (
										<div className="col-md-6 mx-auto">
											<div className="content_div">
												<div className="form-group">
													<label
														for="Address"
														className="required">
														Address
													</label>
													<div className="form-control">
														<form>
															<div className="d-block">
																<label for="location">Deliver to *</label>
																<input
																	type="text"
																	id="location"
																	className="form-control-address"
																	placeholder="Enter a location"
																	value={requestData?.location}
																	onChange={(e) => handleInputChange(e)}
																	maxLength={150}
																/>
															</div>
															<div className="d-block">
																<label for="floor">Apartment, unit, suite, or floor # *</label>
																<input
																	type="text"
																	id="floor"
																	className="form-control-address"
																	value={requestData?.floor}
																	onChange={(e) => handleInputChange(e)}
																	maxLength={150}
																/>
															</div>
															<div className="d-block">
																<label for="city">City *</label>
																<input
																	type="text"
																	id="city"
																	className="form-control-address"
																	value={requestData?.city}
																	onChange={(e) => handleInputChange(e)}
																	maxLength={150}
																/>
															</div>
															<div className="d-flex flex-wrap">
																<div className="d-block w-50 p-2">
																	<label for="state">State/Province*</label>
																	<input
																		type="text"
																		id="state"
																		className="form-control-address"
																		value={requestData?.state}
																		onChange={(e) => handleInputChange(e)}
																		maxLength={150}
																	/>
																</div>
																<div className="d-block w-50 p-2">
																	<label for="postalCode">Postal code*</label>
																	<input
																		type="text"
																		id="postalCode"
																		value={requestData?.postalCode}
																		className="form-control-address"
																		onChange={(e) => handleInputChange(e)}
																		maxLength={10}
																	/>
																</div>
															</div>
															<div className="d-block">
																<label for="country">Country/Region*</label>
																<input
																	type="text"
																	id="country"
																	className="form-control-address"
																	value={requestData?.country}
																	onChange={(e) => handleInputChange(e)}
																	maxLength={150}
																/>
															</div>
															<div className="d-flex flex-wrap">
																{/* <Button buttonClass="main_btn px-2 py-2 mx-2 my-2">
																Save address
															</Button> */}
																<Button
																	buttonClass="main_btn mx-2 my-2"
																	onClick={(e) => {
																		e.preventDefault();
																		setRequestData({
																			...requestData,
																			location: "",
																			city: "",
																			floor: "",
																			state: "",
																			country: "",
																			postalCode: "",
																		});
																	}}>
																	Clear Form
																</Button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="btn">
										<Button
											buttonClass="main_btn px-5 mx-4 my-1 mt-3"
											onClick={handleSendRequest}
											disable={validating} >
											{!validating ? (
												"Send Request"
											) : (
												<>
													<span
														class="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"></span>
													&nbsp; Validating...
												</>
											)}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{notify && (
				<div className="notify_popup_container">
					<div className="notify-body">
						<h4>Notify me when available</h4>
						<button
							type="button"
							class="close notify-close"
							aria-label="Close"
							onClick={() => {
								setNotify(false);
								setRequired(false);
								setNotifyEmail("");
							}}>
							<span aria-hidden="true">&times;</span>
						</button>
						<p>We will send you a notification as soon as this item is available.</p>
						<form>
							<input
								type="email"
								className="form-control"
								value={notifyEmail}
								onChange={(e) => {
									setRequired(false);
									setNotifyEmail(e.target.value);
								}}
								placeholder="eg.username@example.com"
							/>
							{required && <span className="notify-required-text">Your email is required</span>}

							<input
								type="button"
								className="form-control notify-btn"
								value="NOTIFY ME"
								onClick={handleNotifyClick}
							/>
						</form>
						<span className="end-text">
							We respect your privacy and don't share your email with anybody.
						</span>
					</div>
				</div>
			)}
		</>
	);
}

export default Redeem;
