import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { unsubscribeMail } from "../Services/apiServices";

const Unsubscribe = () => {
  const navigate = useNavigate();
  const params = useParams();

  const unsubscribeHandler = async (email) => {
    let regx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!regx.test(email)) {
      console.log("Enter Not Valid!");
      return;
    }
    let emailAddress = { email };
    const res = await unsubscribeMail(emailAddress);
    console.log(res.statusCode);
    if (res.statusCode == 200) {
      console.log("Successfully Unsubscribed");
      navigate("/");
    } else if (res.statusCode == 404) {
      console.log("Email not Found");
    }
  };
  return (
    <>
      <div className="text-center">
        <h3 className="text-danger">Unsubscribe Page</h3>
        <h1 className="text-warning">We're sorry to see you go.</h1>
        <button
          type="submit"
          className="btn btn-danger"
          onClick={(e) => unsubscribeHandler(params.email, e.preventDefault())}
        >
          Unsubscribe
        </button>
      </div>
    </>
  );
};

export default Unsubscribe;
