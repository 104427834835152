import { ethers } from "ethers";
import { networks } from "../Config/index";

export const exportInstance = async (SCAddress, ABI, networkId) => {
  let provider = new ethers.providers.JsonRpcProvider(networks[networkId]);
  let a = new ethers.Contract(SCAddress, ABI, provider);
  console.log("contract", a);
  if (a) {
    return a;
  } else {
    return {};
  }
};

export const getProducts = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };
  try {
    let response = await fetch(`${process.env.REACT_APP_IMP_LINK}/api/v1/getTokenProduct?chain_id=${data.chainId}&contract_address=${data.collection?.toLowerCase()}&token_id=${data.token_id}`, requestOptions );
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("data of product is----->", datas);
    if (datas?.length <= 0) {
      return [];
    }
    return datas;
  } catch (err) {
    console.log("err", err);
    return [];
  }
};

export const getCollections = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL +"/collection/getCollectionInWhichUserOwnTokens",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const getTokenInfo = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };
  try {
    let response = await fetch(`${process.env.REACT_APP_IMP_LINK}/api/v1/tokenDetails?ChainId=${data?.chainId}&ContractAddress=${data.collection?.toLowerCase()}&TokenId=${data.tokenId}`,requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("data of token is----->", datas);
    if (datas?.length <= 0) {
      return [];
    }
    return datas;
  } catch (err) {
    console.log("err", err);
    return false;
  }
};

export const viewItems = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/product/viewAllProducts",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas.data;
  } catch (err) {
    return err;
  }
};

// export const getItemDetails = async (collectionAddress, pid) => {
//   const requestOptions = {
//     method: "GET",
//   };
//   try {
//     let response = await fetch(process.env.REACT_APP_API_BASE_URL +`/product/getItemDetails/${collectionAddress}/${pid}`,requestOptions);
//     const isJson = response.headers.get("content-type")?.includes("application/json");
//     const data = isJson && (await response.json());
//     return data.data;
//   } catch (err) {
//     return err;
//   }
// };

export const getItemDetails = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_METADATA_AUTH_TOKEN,
    },
  };

  try {
    let response = await fetch(process.env.REACT_APP_IMP_LINK +`/api/v1/getProductDetail?chain_id=${data?.chain_id}&contract_address=${data?.contract_address?.toLowerCase()}&product_id=${data?.product_id}`,requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("getItemDetails data", datas);
    return datas;
  } catch (err) {
    return err;
  }
};

export const subscribeMail = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/mail/subscribeMail",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    console.log("err", err);
    return false;
  }
};

export const unsubscribeMail = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/mail/unsubscribeMail",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas;
  } catch (err) {
    console.log("err", err);
    return false;
  }
};

export const getItemsByStatus = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/product/getItemsByStatus",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());

    return datas.data;
  } catch (err) {
    return err;
  }
};

export const sendMail = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/mail/sendMailUserPanel",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const sendRedeemRequest = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/product/sendRedeemRequest",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("datas--->", datas);
    if (datas?.statusCode === 409) {
      return 409;
    }
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const addNotifyEmail = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
    body: JSON.stringify(data),
  };
  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/product/addNotifyEmail",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    return datas.data;
  } catch (err) {
    return err;
  }
};

export const getFaqList = async (data) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_AUTH_KEY,
    },
  };

  try {
    let response = await fetch(process.env.REACT_APP_API_BASE_URL + "/utils/getFaq",requestOptions);
    const isJson = response.headers.get("content-type")?.includes("application/json");
    const datas = isJson && (await response.json());
    console.log("getItemDetails data", datas);
    return datas;
  } catch (err) {
    return err;
  }
};
