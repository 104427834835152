import "../../Assets/style/style.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Toaster } from "react-hot-toast";
import PageNotFound from "../../Pages/PageNotFound";
import Redeem from "../../Pages/redeem";
import LandingPage from "./LandingPage";
import ItemDetails from "../../Pages/itemDetails";
import Faq from "../../Pages/faq";


function App() {

 

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/redeem" exact element={<Redeem />} />
        <Route path="/item-details/:chain_id/:collectionAddress/:pid" exact element={<ItemDetails />} />
        <Route  path="*" element={<PageNotFound />} />
        <Route exact path="/faq" element={<Faq />} />

      </Routes>
      <div><Toaster toastOptions={{
        success: {
          style: {
            background: 'green',
            color: 'white',
            fontFamily: 'fantasy'
          },
        },
        error: {
          style: {
            background: 'red',
            color: 'white',
            fontFamily: 'fantasy',

          },
        },
      }} /></div>
      <Footer />
    </div>
  );
}

export default App;
