import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import WebMain from './WebPortal/Components/Main/index';
import RedeemMain from './RedeemPortal/Components/RedeemMain/index'
import { Toaster } from 'react-hot-toast';

function App() {
  console.log("inside App component")

  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<WebMain />} />
        <Route path="/RedeemPortal/*" element={<RedeemMain />} />
      </Routes>
      <Toaster
          toastOptions={{
            success: {
              style: {
                background: "white",
                color: "green",
                fontFamily: "fantasy",
              },
              icon: '👏',
            },
            error: {
              style: {
                background: "white",
                color: "red",
                fontFamily: "fantasy",
              },
            },
          }}
        />
    </div>
  );
}

export default App;
