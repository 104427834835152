import * as React from 'react';
import "./style.css"
export default function Spinner() {
    return <><div class="wrapper">
    </div>
        <div class="overlay show"></div>
        <div class="spanner show">
            <div class="loader"></div>
        </div></>;
}
