/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { getItemsByStatus, viewItems, getCollections } from "../Services/apiServices";
import { PAGE_LIMIT_PRODUCT, PLACEHOLDER } from "../Helpers/constant";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { BiCopy } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { handleCopyToClipboard } from "../Helpers/getterFunctions";
import Loader from "./../Components/Common/loader"

function CollectionList() {
	const { collectionAddress } = useParams();
	const [allItems, setAllItems] = useState("none");
	const [count, setCount] = useState(0);
	const [allCount, setAllCount] = useState(0);
	const [currPage, setCurrPage] = useState(1);
	const [status, setStatus] = useState("Active");
	const [items, setItems] = useState("none");
	const [iCurrPage, setICurrPage] = useState(1);
	const [collection, setCollection] = useState("");
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		const fetch = async () => {
			setLoader(true)
			try {

				const res = await getCollections({
					collectionAddress: collectionAddress,
					isImported: true,
					isBlocked: false,
				});
				setCollection(res?.results[0][0]);
			} catch (error) {
				console.log("Error in getting collection info", error);
			}
			try {
				let reqbody = {
					page: currPage,
					limit: PAGE_LIMIT_PRODUCT,
					isBlocked: false,
					isImported: true,
					collectionAddress: collectionAddress,
				};
				const res = await viewItems(reqbody);
				setAllCount(res?.count);
				if (res?.results && res?.results?.length > 0) setAllItems(res?.results);
				else setAllItems([]);
			} catch (error) {
				console.log("Error in getCollection", error);
			}
			setLoader(false)
		};
		fetch();
	}, [currPage]);

	useEffect(() => {
		handleStatusTabChange(status);
	}, [iCurrPage]);

	const handleStatusTabChange = async (_status) => {
		setLoader(true)
		try {
			let reqBody = {
				page: iCurrPage,
				limit: PAGE_LIMIT_PRODUCT,
				isImported: true,
				isBlocked: false,
				status: _status,
				collectionAddress: collectionAddress,
			};
			const itemList = await getItemsByStatus(reqBody);
			setCount(itemList?.count);
			if (itemList?.results && itemList?.results?.length > 0) setItems(itemList?.results);
			else setItems([]);
		} catch (error) {
			console.log("Error in handleStatusTabChange", error);
		}
		setLoader(false)
	};

	return (
		<>
			{loader && <Loader />}
			<div
				className="collection_banner"
				style={{ backgroundImage: `url(${collection?.banner})` }}></div>
			<div className="collection_profile">
				{collection?.image && <img
					src={collection?.image}
					alt="profile"
					className="img-fluid profile_img"
				/>}
			</div>
			<div className="collection_name text-center mt-4">
				<h2>{collection?.collectionName}</h2>
				<div className="input-group header_search_bar collection_address_bar" >
					<input
						type="text"
						className="form-control search_bar_input"
						readOnly
						value={collection?.collectionAddress}

					/>
					<div className="input-group-append " onClick={() => handleCopyToClipboard(collection?.collectionAddress)}>
						<span className="form-control search_bar_icon">
							<BiCopy />
						</span>
					</div>
				</div>
			</div>

			<div className="tabs_div mt-5">
				<div className="container">
					<ul
						className="nav tabs_ul justify-content-around nav-pills mb-3"
						id="pills-tab"
						role="tablist">
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn active"
								id="pills-active-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-active"
								type="button"
								role="tab"
								aria-controls="pills-active"
								aria-selected="true"
								onClick={() => {
									setStatus("Active");
									setItems("none");
									setICurrPage(1);
									handleStatusTabChange("Active");
								}}>
								<div className="activeTab"></div> Active
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-future-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-future"
								type="button"
								role="tab"
								aria-controls="pills-future"
								aria-selected="false"
								onClick={() => {
									setStatus("Upcoming");
									setItems("none");
									setICurrPage(1);
									handleStatusTabChange("Upcoming");
								}}>
								<div className="futureTab"></div> Future
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-expired-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-expired"
								type="button"
								role="tab"
								aria-controls="pills-expired"
								aria-selected="false"
								onClick={() => {
									setStatus("Expired");
									setItems("none");
									setICurrPage(1);
									handleStatusTabChange("Expired");
								}}>
								<div className="expiredTab"></div> Expired
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-all-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-all"
								type="button"
								role="tab"
								aria-controls="pills-all"
								aria-selected="false">
								{" "}
								All
							</button>
						</li>
					</ul>
					<div
						className="tab-content"
						id="pills-tabContent">
						<div
							className="tab-pane fade show active"
							id="pills-active"
							role="tabpanel"
							aria-labelledby="pills-active-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{items !== "none" ? (
										<>
											<div className="row">
												{items?.length > 0 &&
													items?.map((data, index) => {
														return (
															<a
																href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
																key={index}>
																<div className="custom_card">
																	<div className="collection_img">
																		<a
																			href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}>
																			<img
																				src={data?.low_image ? data?.low_image : PLACEHOLDER}
																				alt="collection"
																			/>
																		</a>
																		<p># {data?.productId}</p>
																	</div>
																	<div className="content">
																		<h5>{data.productName}</h5>
																		<p>{data?.productDesc?.length > 20 ? (data?.productDesc.slice(0, 20) + "...") : data.productDesc}</p>
																	</div>
																</div>
															</a>
														);
													})}
											</div>
											{items?.length > 0 && Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
												<Pagination
													{...bootstrap5PaginationPreset}
													current={iCurrPage}
													total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
													onPageChange={(p) => setICurrPage(p)}
												/>
											)}
											{items?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Result Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-future"
							role="tabpanel"
							aria-labelledby="pills-future-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{items !== "none" ? (
										<>
											<div className="row">
												{items?.length > 0 &&
													items?.map((data, index) => {
														return (
															<a
																href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
																key={index}>
																<div className="custom_card">
																	<div className="collection_img">
																		<a
																			href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}>
																			<img
																				src={data?.low_image ? data?.low_image : PLACEHOLDER}
																				alt="collection"
																			/>
																		</a>
																		<p># {data?.productId}</p>
																	</div>
																	<div className="content">
																		<h5>{data.productName}</h5>
																		<p>{data?.productDesc?.length > 20 ? (data?.productDesc.slice(0, 20) + "...") : data.productDesc}</p>
																	</div>
																</div>
															</a>
														);
													})}
											</div>
											{items?.length > 0 && Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
												<Pagination
													{...bootstrap5PaginationPreset}
													current={iCurrPage}
													total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
													onPageChange={(p) => setICurrPage(p)}
												/>
											)}
											{items?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Result Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-expired"
							role="tabpanel"
							aria-labelledby="pills-expired-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{items !== "none" ? (
										<>
											<div className="row">
												{items?.length > 0 &&
													items?.map((data, index) => {
														return (
															<a
																href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
																key={index}>
																<div className="custom_card">
																	<div className="collection_img">
																		<a
																			href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}>
																			<img
																				src={data?.low_image ? data?.low_image : PLACEHOLDER}
																				alt="collection"
																			/>
																		</a>
																		<p># {data?.productId}</p>
																	</div>
																	<div className="content">
																		<h5>{data.productName}</h5>
																		<p>{data?.productDesc?.length > 20 ? (data?.productDesc.slice(0, 20) + "...") : data.productDesc}</p>
																	</div>
																</div>
															</a>
														);
													})}
											</div>
											{items?.length > 0 && Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
												<Pagination
													{...bootstrap5PaginationPreset}
													current={iCurrPage}
													total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
													onPageChange={(p) => setICurrPage(p)}
												/>
											)}
											{items?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Result Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-all"
							role="tabpanel"
							aria-labelledby="pills-all-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{allItems !== "none" ? (
										<>
											<div className="row">
												{allItems?.length > 0 &&
													allItems?.map((data, index) => {
														return (
															<a href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
																key={index}>
																<div className="custom_card">
																	<div className="collection_img">
																		<a href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}>
																			<img
																				src={data?.low_image ? data?.low_image : PLACEHOLDER}
																				alt="collection"
																			/>
																		</a>
																		<p># {data?.productId}</p>
																	</div>
																	<div className="content">
																		<h5>{data.productName}</h5>
																		<p>{data?.productDesc?.length > 20 ? (data?.productDesc.slice(0, 20) + "...") : data.productDesc}</p>
																	</div>
																</div>
															</a>
														);
													})}
											</div>
											{allItems?.length > 0 && Math.ceil(allCount / PAGE_LIMIT_PRODUCT) > 1 && (
												<Pagination
													{...bootstrap5PaginationPreset}
													current={currPage}
													total={Math.ceil(allCount / PAGE_LIMIT_PRODUCT)}
													onPageChange={(p) => setCurrPage(p)}
												/>
											)}
											{allItems?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Result Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CollectionList;
