import { toast } from "react-hot-toast";
import { ethers } from "ethers";
import { exportInstance, getProducts, getTokenInfo } from "./../Services/apiServices";

import erc721Abi from "./../Config/abis/erc721.json";
import eventEmitter from "../events/events";

export const getUserTokens = async (collectionAddress, walletAddress, networkId) => {
	console.log("network id", networkId);
	eventEmitter.emit("loader_message", "msg", "Fetching Contract Info...");
	if (walletAddress) {
		const contract = await exportInstance(collectionAddress, erc721Abi, networkId);
		console.log("contract", contract, walletAddress);
		try {
			eventEmitter.emit("loader_message", "msg", "Fetching Balance...");
			let bal = await contract.balanceOf(walletAddress);
			console.log("balance", bal.toString());
			let percent = 0.0;

			let tokenIds = [];
			let basicInfo = [];
			eventEmitter.emit("loader_message", "percent", 0);
			for (let i = 0; i < Number(bal); i++) {
				let id = await contract.tokenOfOwnerByIndex(walletAddress, i);
				console.log("token id", i, "--->", id);
				percent = Math.round(parseFloat(i / bal) * 100);
				eventEmitter.emit("loader_message", "percent", percent);
				console.log("percent", percent);
				// eventEmitter.emit("updateProgress", percent)
				tokenIds.push(id.toString());
				let info = await getTokenInfo({
					collection: collectionAddress,
					tokenId: id,
					chainId: networkId,
				});
				if (info !== false) {
					console.log("images", info);
					console.log("info[0]?.image", info[0]?.image);
					basicInfo.push({
						image: info?.length > 0 ? info[0]?.image : "",
						name: info?.length > 0 ? info[0]?.name : "-",
						// description: info?.length > 0 ? info[0]?.description : ""
					});
				}
				// eventEmitter.emit("updateTokenList", tokenIds, images)
			}
			eventEmitter.emit("loader_message", "percent", 100);

			eventEmitter.emit("loader_message", "msg", "token fetched successfully");

			return {
				tokenIds: tokenIds,
				basicTokenInfo: basicInfo,
			};
		} catch (err) {
			console.log("err", err);
			toast.error(err.code);
			return {
				tokenIds: [],
				basicTokenInfo: [],
			};
		}
	} else
		return {
			tokenIds: [],
			basicTokenInfo: [],
		};
};

export const getProductByTokenId = async (collectionAddress, tokenId, currAccount, networkId) => {
	console.log("currAccount", currAccount, currAccount !== "", tokenId);
	if (
		currAccount !== "" &&
		currAccount !== null &&
		currAccount !== undefined &&
		currAccount !== "none"
	) {
		console.log("----->>>>");
		const contract = await exportInstance(collectionAddress, erc721Abi, networkId);
		let bal = await contract.balanceOf(currAccount);

		if (tokenId === "Select Token") return [];
		if (bal.toString() === "0") {
			return [];
		}
		let resp = await getProducts({
			collection: collectionAddress,
			token_id: tokenId,
			chainId: networkId,
		});
		console.log("respp", resp);
		return resp;
	} else if (currAccount !== "none") {
		const contract = await exportInstance(collectionAddress, erc721Abi, networkId);
		let totalSupply = await contract.totalSupply();
		let resp = await getProducts({
			collection: collectionAddress,
			token_id: [0 + "-" + Number(totalSupply.toString())],
			chainId: networkId,
		});
		console.log("respp", resp);
		return resp;
	}
};

export const getDummyCollectionImage = () => {
	return "https://i.seadn.io/gae/bJYPUpJDkeiodliJwgFfmhJ3s3kpwKa4c3-wzHw_ICiWFBTr--C77q7POjtFAJ_V5YgtXIGphW6Q3TfT-NdturPjkKFxn8zkSv8ZCg?auto=format&w=1920";
};

export const getDummyProductImage = () => {
	return "https://i.seadn.io/gae/bJYPUpJDkeiodliJwgFfmhJ3s3kpwKa4c3-wzHw_ICiWFBTr--C77q7POjtFAJ_V5YgtXIGphW6Q3TfT-NdturPjkKFxn8zkSv8ZCg?auto=format&w=1920";
};

export const checkIfOwned = async (collectionAddress, tokenId, currOwner, networkId) => {
	let contract = await exportInstance(collectionAddress, erc721Abi, networkId);
	let owner = await contract.ownerOf(tokenId);
	console.log("ownerrr", owner);
	if (currOwner !== owner) {
		return false;
	}
	return true;
};

export const handleCopyToClipboard = async (copyMe) => {
	console.log("copy me");
	try {
		await navigator.clipboard.writeText(copyMe);
		toast.success("Copied!");
	} catch (err) {
		console.log("err", err);
		toast.error("Failed to copy!");
	}
};

export const isEmpty = (obj) => {
	for (var prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false;
		}
	}
	return JSON.stringify(obj) === JSON.stringify({});
};


export const checkIfTokenOwnedByAdd = async (collectionAddress, currOwner, networkId, token_id) => {
    try {
		
        let contract = await exportInstance(collectionAddress, erc721Abi, networkId)
        let owner = await contract.ownerOf(token_id);
        if (currOwner?.toLowerCase() !== owner?.toLowerCase()) {
            return false
        }
        return true
    }
    catch (e) {
        console.log("err", e)
        return false
    }
}