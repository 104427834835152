import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UserForm from "../Components/Misc/userForm";
import { Button } from "../Components/Misc/Button";
import { getItemDetails } from "../Services/apiServices";
import Loader from "../Components/Common/loader";

function ItemDetails() {
  const [currAccount, setCurrAccount] = useState("");
  const { chain_id, pid, collectionAddress } = useParams();
  const location = useLocation();
  const [openForm, setOpenForm] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [btnName, setBtnName] = useState("Claim Now");
  const [product, setProduct] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [status, setStatus] = useState("");
  const [color, setColor] = useState("");
  const [loader, setLoader] = useState(false);

  const handleReloadData = () => {
    setReloadData(!reloadData);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  const updateButtonName = (id) => {
    console.log("id in update", id);
    setBtnName("Request Sent");
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("decrypt_redeem_user_account") != null &&
      sessionStorage.getItem("decrypt_redeem_user_account") !== "null"
    )
      setCurrAccount(sessionStorage.getItem("decrypt_redeem_user_account"));
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      try {
        let data = {
          contract_address: collectionAddress,
          product_id: pid,
          chain_id: chain_id,
        };
        const res = await getItemDetails(data);
        console.log("response", res);
        if (res) setProduct(res);
        if (
          (res?.start_date,
          res?.end_date,
          Number(res?.start_date) < Math.round(new Date().valueOf() / 1000) &&
            Number(res?.end_date) > Math.round(new Date().valueOf() / 1000))
        ) {
          setStatus("Active");
          setColor("#3BE211");
        } else if (
          Number(res?.start_date) < Math.round(new Date().valueOf() / 1000) &&
          Number(res?.end_date) < Math.round(new Date().valueOf() / 1000)
        ) {
          setStatus("Expired");
          setColor("#8A959E");
        } else if (
          Number(res?.start_date) > Math.round(new Date().valueOf() / 1000)
        ) {
          setStatus("Upcoming");
          setColor("#E2B411");
        } else {
          setStatus("");
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    };
    fetch();
  }, [pid, collectionAddress, chain_id]);

  return (
    <>
      {loader && <Loader />}
      {openForm ? (
        <UserForm
          show={openForm}
          handleClose={handleClose}
          tokenId={location?.state?.tokenId}
          collectionAddress={location?.state?.collection}
          productId={location?.state?.productId}
          handleReloadData={handleReloadData}
          updateButtonName={updateButtonName}
        ></UserForm>
      ) : (
        ""
      )}

      <div className="container item-container item_details_div">
        {/* <div className="row"><a href={`/redeem/${location?.state?.collection}`}>Back</a></div> */}
        <div className="row gy-3 item-row align-items-center">
          <div className="col-lg-6">
            <div className="item_detail_img_div d-flex justify-content-center align-items-center">
              <img
                className="item-img"
                alt="itemImage"
                src={product?.image_url}
              ></img>
            </div>
          </div>
          <div className="col-lg-6 detail-container">
            <div className="w-100 d-flex justify-content-end align-items-center">
              {status !== "" && (
                <>
                  <div
                    className="statusColor"
                    style={{ background: color }}
                  ></div>
                  <p className="statusText">{status}</p>
                </>
              )}
            </div>
            <div className="upper_half">
              <h3 className="product-name">
                {/* {location?.state?.productName} */}
                {product.name}
              </h3>
              <p>
                {/* {location?.state?.productDesc} */}
                {product.description}
              </p>
            </div>
            <div className="lower_half mt-3">
              <h3 className="product-name">Details</h3>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Product Id </p>
                <p className="right"># {product.product_id}</p>
              </div>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Collection Name </p>
                <p className="right">
                  {product?.collectionData?.length > 0
                    ? product?.collectionData[0]?.collectionName
                    : "-"}
                </p>
              </div>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Collection Address</p>
                <p className="right">
                  {product?.contract_address?.slice(0, 4) +
                    "..." +
                    product?.contract_address?.slice(38, 42)}
                </p>
              </div>
              {/* <div className="w-100 d-flex justify-content-between">
								<p className="left">Product Id </p>
								<p className="right">#R-154631</p>
							</div> */}
            </div>
            <div className="my-4 d-flex flex-wrap justify-content-center">
              {status === "Active" ? (
                <Button
                  buttonClass="main_btn px-5 mx-1 mb-1"
                  onClick={() => {
                    setOpenForm(true);
                  }}
                >
                  Claim
                </Button>
              ) : status === "Upcoming" ? (
                <Button
                  buttonClass="main_btn px-5 mx-1 mb-1"
                  onClick={() => {
                    setOpenForm(true);
                  }}
                >
                  Notify
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 notes_div">
            <div className="w-100 d-flex justify-content-start align-items-center my-3">
              <div className="notes"></div>
              <h3 className="product-name">Notes</h3>
            </div>
            <p>
              {!showMore ? (
                <p className="text-start">
                  {product?.notes
                    ? product?.notes?.slice(0, 120) +
                      (product?.notes?.length > 120 ? "..." : "")
                    : "-"}
                </p>
              ) : (
                <p className="text-start">
                  {" "}
                  {product?.notes ? product?.notes : "-"}
                </p>
              )}
              {product?.notes?.length > 120 ? (
                <a className="showmore"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? "Show More" : "Show Less"}
                </a>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemDetails;
