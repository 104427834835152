/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { getProductByTokenId } from "../Helpers/getterFunctions";
import Spinner from "../Components/Common/Spinner";
import moment from "moment";
import { account } from "../Config/localStorageNames";
import { Button } from "../Components/Misc/Button";

function Items(props) {
	const [status, setStatus] = useState("Active");
	const [items, setItems] = useState("none");
	const [loader, setLoader] = useState(false);
	const [activeItems, setActiveItems] = useState("none");
	const [expiredItems, setExpiredItems] = useState("none");
	const [upcomingItems, setUpcomingItems] = useState("none");
	const [selectedItem, setSelectedItem] = useState("");

	useEffect(() => {
		const fetch = async () => {
			setLoader(true);
			let _products = [],
				_activeList = [],
				_expiredList = [],
				_upcomingList = [];
			let data = await getProductByTokenId(
				props.selectedCollection?.collectionAddress,
				props.selectedToken,
				sessionStorage.getItem(account),
				props.selectedCollection?.chainId
			);

			data?.map((d, i) => {
				_products = _products?.concat(d);
			});
			const ct = moment().unix().toString();

			_activeList = _products?.filter((p) => {
				return p?.start_date <= ct && p?.end_date > ct;
			});

			_upcomingList = _products?.filter((p) => {
				return p?.start_date > ct;
			});

			_expiredList = _products?.filter((p) => {
				return p?.end_date <= ct;
			});

			setActiveItems(_activeList);
			setUpcomingItems(_upcomingList);
			setExpiredItems(_expiredList);
			setItems(_products);
			setLoader(false);
		};
		if (props.showItems === true) fetch();
	}, [props.showItems]);

	return (
		<>
			<div className="tabs_div mt-5">
				<div className="container">
					<ul
						className="nav tabs_ul justify-content-around nav-pills mb-3"
						id="pills-tab"
						role="tablist">
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn active"
								id="pills-active-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-active"
								type="button"
								role="tab"
								aria-controls="pills-active"
								aria-selected="true"
							// onClick={() => {
							// 	setStatus("Active");
							// 	setSelectedItem("")
							// }}
							>
								<div className="activeTab"></div> Active
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-future-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-future"
								type="button"
								role="tab"
								aria-controls="pills-future"
								aria-selected="false"
							// onClick={() => {
							// 	setStatus("Upcoming");
							// 	setSelectedItem("")
							// }}
							>
								<div className="futureTab"></div> Future
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-expired-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-expired"
								type="button"
								role="tab"
								aria-controls="pills-expired"
								aria-selected="false"
							// onClick={() => {
							// 	setStatus("Expired");
							// 	setSelectedItem("")
							// }}
							>
								<div className="expiredTab"></div> Expired
							</button>
						</li>
						<li
							className="nav-item"
							role="presentation">
							<button
								className="nav-link tabs_btn"
								id="pills-all-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-all"
								type="button"
								role="tab"
								aria-controls="pills-all"
								aria-selected="false"
							// onClick={() => {
							// 	setStatus("");
							// 	setItems("none")
							// 	setSelectedItem("")
							// }}
							>
								{" "}
								All
							</button>
						</li>
					</ul>
					<div
						className="tab-content"
						id="pills-tabContent">
						<div
							className="tab-pane fade show active"
							id="pills-active"
							role="tabpanel"
							aria-labelledby="pills-active-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{loader && <Spinner />}
									{activeItems !== "none" ? (
										<>
											<div className="row">
												{activeItems?.length > 0 &&
													activeItems?.map((data, index) => {
														return (
															<div
																onClick={() => setSelectedItem(data)}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
																key={index}>
																<div className={`custom_card ${data.product_id === selectedItem?.product_id ? "selected" : ""}`}>
																	<div className="collection_img">
																		<img
																			src={data?.image_url}
																			alt="collection"
																		/>

																		<p># {data?.product_id}</p>
																	</div>
																	<div className="content">
																		<h5>{data.name}</h5>
																		<p>
																			{data?.description?.length > 20
																				? data?.description.slice(0, 20) + "..."
																				: data.description}
																		</p>
																	</div>
																</div>
															</div>
														);
													})}
											</div>
											{!loader && activeItems !== "none" && activeItems?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Item Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-future"
							role="tabpanel"
							aria-labelledby="pills-future-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{loader && <Spinner />}
									{upcomingItems !== "none" ? (
										<>
											<div className="row">
												{upcomingItems?.length > 0 &&
													upcomingItems?.map((data, index) => {
														return (
															<div
																onClick={() => setSelectedItem(data)}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
																key={index}>
																<div className={`custom_card ${data.product_id === selectedItem?.product_id ? "selected" : ""}`}>
																	<div className="collection_img">
																		<img
																			src={data?.image_url}
																			alt="collection"
																		/>

																		<p># {data?.product_id}</p>
																	</div>
																	<div className="content">
																		<h5>{data.name}</h5>
																		<p>
																			{data?.description?.length > 20
																				? data?.description.slice(0, 20) + "..."
																				: data.description}
																		</p>
																	</div>
																</div>
															</div>
														);
													})}
											</div>
											{!loader && upcomingItems !== "none" && upcomingItems?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Item Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-expired"
							role="tabpanel"
							aria-labelledby="pills-expired-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{loader && <Spinner />}
									{expiredItems !== "none" ? (
										<>
											<div className="row">
												{expiredItems?.length > 0 &&
													expiredItems?.map((data, index) => {
														return (
															<div
																onClick={() => setSelectedItem(data)}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
																key={index}>
																<div className={`custom_card ${data.product_id === selectedItem?.product_id ? "selected" : ""}`}>
																	<div className="collection_img">
																		<img
																			src={data?.image_url}
																			alt="collection"
																		/>

																		<p># {data?.product_id}</p>
																	</div>
																	<div className="content">
																		<h5>{data.name}</h5>
																		<p>
																			{data?.description?.length > 20
																				? data?.description.slice(0, 20) + "..."
																				: data.description}
																		</p>
																	</div>
																</div>
															</div>
														);
													})}
											</div>

											{!loader && expiredItems !== "none" && expiredItems?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Item Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>

						<div
							className="tab-pane fade"
							id="pills-all"
							role="tabpanel"
							aria-labelledby="pills-all-tab">
							<section className="recent_items items_page_cards">
								<div className="container text-center">
									{loader && <Spinner />}
									{items !== "none" ? (
										<>
											<div className="row">
												{items?.length > 0 &&
													items?.map((data, index) => {
														return (
															<div
																onClick={() => setSelectedItem(data)}
																className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
																key={index}>
																<div className={`custom_card ${data.product_id === selectedItem?.product_id ? "selected" : ""}`}>
																	<div className="collection_img">
																		<img
																			src={data?.image_url}
																			alt="collection"
																		/>

																		<p># {data?.product_id}</p>
																	</div>
																	<div className="content">
																		<h5>{data.name}</h5>
																		<p>{data?.description}</p>
																	</div>
																</div>
															</div>
														);
													})}
											</div>

											{items?.length === 0 && (
												<span
													variant="lg"
													className="NoData">
													No Item Found
												</span>
											)}
										</>
									) : (
										""
									)}
								</div>
							</section>
						</div>
					</div>
				</div>
				<div className="container button_group">
					{!loader && (
						
							<Button
								buttonClass="main_btn px-5 mx-4 my-1"
								onClick={(e) => {
									e.preventDefault();
									// setStep(2);
									props.handleButtonClick(2);
								}}>
								Back
							</Button>
						
					)}
					{!loader && (
						
							<Button
								buttonClass="main_btn px-5 mx-4 my-1"
								disable={selectedItem === ""}
								onClick={(e) => {
									e.preventDefault();
									props.handleButtonClick(4, selectedItem);
								}}>
								Next
							</Button>
						
					)}
				</div>
			</div>
		</>
	);
}

export default Items;
