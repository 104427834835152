

export const messages = {
  "signMessage": "This is to verify if you are the real address to send this request",
  "emailAddedToNotifySuccess": "You will be notified once this item is available.",
  "emailAddedToNotifyError": "Error while adding email for notification",
  "requestSuccess": "Request sent successfully",
  "requestError": "Error while sending request",
  "userDenied": "User denied request",
  "requestAlreadySent": "Request Already Sent",
  "invalidChar": "Invalid character"
};

export const validationMessage = (param, type) => {
  return "Please " + type + " " + param;
};
