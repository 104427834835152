import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ethers } from 'ethers'
import toast from 'react-hot-toast';
import { messages } from './../../Config/messages';

function UserForm(props) {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [pincode, setPincode] = useState("")
    const [loading, setLoading] = useState(false)
    const [notes, setNotes] = useState()

    useEffect(() => { console.log("name", name) }
        , [name])

    const sign = async () => {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner()
            let rawMessage = messages.signMessage
            console.log(rawMessage)
            const signature = await signer.signMessage(rawMessage);
            const messageSender = ethers.utils.verifyMessage(rawMessage, signature);
            console.log("signature", signature, messageSender)
            return signature
        }
        catch (e) {
            console.log("e", e)
            toast.error(e.code)
            return false
        }
    }

    function allnumeric(inputtxt) {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        if (!Number(inputtxt)) {
            return false
        }
        if (inputtxt === '' || re.test(inputtxt)) {
            // alert('Your Registration number has accepted....');
            return true
        }
        else {
            // alert('Please input numeric characters only');
            return false
        }

    }

    // const handleClaim = async () => {
    //     console.log("nameee", name?.trim())
    //     if (name?.trim() === "") {
    //         toast.error("Please Select name");
    //         return;
    //     }
    //     if (email?.trim() === "") {
    //         toast.error("Please Add email Address");
    //         return;
    //     }
    //     if (email?.trim() !== "") {
    //         if (validator.isEmail(email)) {
    //             //toast.error('Valid Email :)')
    //         } else {
    //             toast.error('Enter valid Email!')
    //             return;
    //         }
    //     }
    //     if (phone?.trim() === "") {
    //         toast.error("Please Select phone");
    //         return;
    //     }
    //     if (address?.trim() === "") {
    //         toast.error("Please Add Complete Address");
    //         return;
    //     }
    //     if (pincode?.trim() === "") {
    //         toast.error("Please Add  pincode");
    //         return;
    //     }


    //     setLoading(true)
    //     let signature = await sign();
    //     if (signature === false) {
    //         setLoading(false)
    //         return
    //     }
    //     console.log("pppp------------??", props?.networkId)
    //     const messageSender = ethers.utils.verifyMessage(messages.signMessage, signature);
    //     let isOwned = await checkIfOwned(props.collectionAddress, props.tokenId, messageSender, props.networkId)
    //     console.log("isOwneddd", isOwned)
    //     if (!isOwned) {
    //         toast.error("You don't own this NFT");
    //         setLoading(false)
    //         window.location.reload()
    //         return
    //     }



    //     let req = {
    //         name: name?.trim(),
    //         email: email?.trim(),
    //         phoneNumber: phone?.trim(),
    //         address: address?.trim(),
    //         pinCode: pincode?.trim(),
    //         tokenId: props.tokenId,
    //         collectionAddress: props.collectionAddress ? "" + props.collectionAddress : "",
    //         productId: props.productId,
    //         walletAddress: sessionStorage.getItem("decrypt_redeem_user_account"),
    //         signature: signature,
    //         message: messages.signMessage,
    //         notes: notes?.trim(),
    //         chainId: props.networkId
    //     }
    //     console.log("iddd", "prod-" + props.productId)

    //     let res = await sendRedeemRequest(req)
    //     if (res === 409) {
    //         toast.error(messages.requestAlreadySent);
    //         setLoading(false)
    //         props.handleReloadData()
    //         props.handleClose()
    //         return
    //     }
    //     if (res !== false) {
    //         props.updateButtonName("prod-" + props.productId)
    //         toast.success(messages.requestSuccess)
    //         props.handleReloadData()
    //         props.handleClose()
    //     }
    //     else {
    //         toast.error(messages.requestError)
    //     }
    //     setLoading(false)
    //     //console.log("Res", res)
    // }

    return (
        <>
            {/* {loading ? "Loading.." : ""} */}
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header >
                    <Modal.Title>Checkout Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={loading}
                                placeholder="ABC"
                                autoFocus
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                disabled={loading}
                                placeholder="name@example.com"
                                autoFocus
                                value={email}
                                onChange={(e) => {


                                    setEmail(e.target.value)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="989349..."
                                autoFocus
                                value={phone}
                                disabled={loading}
                                onChange={(e) => {
                                    if (allnumeric(Number(e.target.value)) || e.target.value === "") {
                                        setPhone(e.target.value)
                                    }
                                    else {
                                        toast.error(messages.invalidChar)
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Complete Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="H-123 xyz colony, India"
                                autoFocus
                                disabled={loading}
                                value={address}
                                onChange={(e) => { setAddress(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="201009"
                                autoFocus
                                disabled={loading}
                                value={pincode}
                                onChange={(e) => {
                                    if (allnumeric(Number(e.target.value)) || e.target.value === "") {
                                        setPincode(e.target.value)
                                    }
                                    else {
                                        toast.error(messages.invalidChar)
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label htmlFor="exampleFormControlTextarea1">Notes</Form.Label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setNotes(e.target.value)}></textarea>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={loading} onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={loading}
                    // onClick={handleClaim}
                    >
                        Claim Now
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UserForm