import "../../Assets/style/style.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Toaster } from "react-hot-toast";
import Collections from "../../Pages/collections";
import Items from "../../Pages/items";
import LandingPage from "./LandingPage";
import ItemDetails from "../../Pages/itemDetails";
import Unsubscribe from "../../Pages/unsubscribe";
import CollectionList from "../../Pages/collectionList";
import ContactUs from "../../Pages/contactUs";
import PageNotFound from "../../Pages/PageNotFound";
import Faq from "../../Pages/faq";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route exact path="/collections" element={<Collections />}>
          <Route
            exact
            path="/collections:searchedText"
            element={<Collections />}
          />
        </Route>

        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route
          exact
          path="/collection-details/:collectionAddress"
          element={<CollectionList />}
        />

        <Route exact path="/items" element={<Items />}>
          <Route exact path="/items:searchedText" element={<Items />} />
        </Route>
        <Route exact path="/unsubscribe/:email" element={<Unsubscribe />} />
        <Route
          exact
          path="/item-details/:chain_id/:collectionAddress/:pid"
          element={<ItemDetails />}
        />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
      <div>
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "green",
                color: "white",
                fontFamily: "fantasy",
              },
            },
            error: {
              style: {
                background: "red",
                color: "white",
                fontFamily: "fantasy",
              },
            },
          }}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
