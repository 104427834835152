/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import qtyIcon from "../Assets/images/icons/QtyIcon.png";
import coins from "../Assets/images/icons/coins.png";
import { getCollections } from "../Services/apiServices";
import { PAGE_LIMIT_COLLECTION, PLACEHOLDER } from "../Helpers/constant";
import Pagination, { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { useParams } from "react-router-dom";
import Loader from "./../Components/Common/loader"
import Spinner from "react-bootstrap/esm/Spinner";

function Collections() {
	const [collections, setCollections] = useState([]);
	const [loader, setLoader] = useState(false);
	const [count, setCount] = useState(0);
	const [currPage, setCurrPage] = useState(1);
	const { searchedText } = useParams()

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoader(true)
				let reqbody = {
					page: currPage,
					limit: PAGE_LIMIT_COLLECTION,
					isBlocked: false,
					isImported: true,
					collectionName: searchedText
				};
				const res = await getCollections(reqbody);
				setCount(res?.count);
				if (res?.results && res?.results[0]?.length > 0) setCollections(res?.results[0]);
				setLoader(false)
			} catch (error) {
				console.log("Error in getCollection", error);
				setLoader(false)
			}
		};
		fetch();
	}, [currPage]);




	return (
		<>
			{loader && <Loader />}
			<section className="Banner_section">
				<div className="container ">
					<div className="banner_container">
						<h2>Collections</h2>
					</div>
				</div>
			</section>

			<section className="hot_collection">
				<div className="container text-center">
					<div className="row">
						{collections?.length > 0 &&
							collections?.map((data, index) => {
								return (
									<a href={`/collection-details/${data?.collectionAddress}`}
										className="col-md-4 col-sm-6 col-12 p-3 item-anchor-tag"
										key={index}>
										<div className="custom_card">
											<div className="collection_img">
												<img
													src={data?.low_banner ? data?.low_banner : PLACEHOLDER}
													//  onLoad={(e) => {
													// 	e.target.src = data?.low_banner ? data?.low_banner : PLACEHOLDER
													// }}
													alt="collection"
												/>
											</div>
											<div className="profile_img">
												<img
													src={data?.low_image ? data?.low_image : PLACEHOLDER} 
													// onLoad={(e) => {
													// 	e.target.src = data?.low_image ? data?.low_image : PLACEHOLDER

													// }}
													alt="Avatar"
												/>
											</div>
											<div className="content">
												<div className="d-flex align-items-center">
													<img
														src={qtyIcon}
														alt="Qty"
													/>
													<p>{data?.totalProducts} </p>
												</div>
												<img
													src={coins}
													alt="Qty"
												/>
											</div>
											<h5 className="CollecNameHeading">{data?.collectionName}</h5>
										</div>
									</a>
								);
							})}
					</div>
					{collections?.length > 0 && Math.ceil(count / PAGE_LIMIT_COLLECTION) > 1 && (
						<Pagination
							{...bootstrap5PaginationPreset}
							current={currPage}
							total={Math.ceil(count / PAGE_LIMIT_COLLECTION)}
							onPageChange={(p) => setCurrPage(p)}
						/>
					)}
				</div>
			</section>
		</>
	);
}

export default Collections;
