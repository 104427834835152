
import { ethers, providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider"
import { supportedRpcs } from "./supportedRpcs";
import { chainId, account, connectedrole } from "./../../../Config/localStorageNames";

export const walletConnect = async () => {
    try {
        const _provider = new WalletConnectProvider({
            rpc: supportedRpcs,
        });
        const web3Provider = new providers.Web3Provider(_provider);
        await _provider.enable();
        const res = await web3Provider.listAccounts();
        console.log("Accounts", res)

        console.log("Provider", _provider)

        console.log("heree3333")
        sessionStorage.setItem(account, res[0])
        sessionStorage.setItem(connectedrole, "owner")
        sessionStorage.setItem(chainId, JSON.parse(sessionStorage.getItem("walletconnect"))?.connected === true ? JSON.parse(sessionStorage.getItem("walletconnect"))?.chainId : "")
        sessionStorage.setItem("decrypt_redeem_user_connectedWallet", "walletConnect")
        window.location.href = "/RedeemPortal/redeem"

        return _provider
    }
    catch (err) {
        console.log("err", err)
    }

}


export const connectWithMetamask = async () => {
    if (window.ethereum) {
        window.ethereum.request({ method: 'eth_requestAccounts' })
            .then(async (res) => {
                console.log(res[0], window.ethereum.networkVersion)
                sessionStorage.setItem(account, res[0])
                sessionStorage.setItem(connectedrole, "owner")
                sessionStorage.setItem(chainId, window.ethereum.networkVersion)
                sessionStorage.setItem("decrypt_redeem_user_connectedWallet", "metamask")
                // console.log("Provider meta", window?.ethereum)
                window.location.href = "/RedeemPortal"
            })
    } else {
        alert("install metamask extension!!")
    }

}


export const getCurrentProvider = async () => {
    let wallet = sessionStorage.getItem("decrypt_redeem_user_connectedWallet")
    let _provider
    if (wallet === "walletConnect") {
        _provider = new WalletConnectProvider({
            rpc: supportedRpcs,
        });
        //  Wrap with Web3Provider from ethers.js
        const web3Provider = new providers.Web3Provider(_provider);
        await _provider.enable();
        return web3Provider
    }
    else if (wallet === "metamask") {
        _provider = new ethers.providers.Web3Provider(window.ethereum)
        return _provider
    }
}

export const getCurrentAccount = async () => {
    let wallet = sessionStorage.getItem("decrypt_redeem_user_connectedWallet")

    if (wallet === "walletConnect") {
        let _provider = await getCurrentProvider()
        const res = await _provider.listAccounts();
        console.log("Accounts", res)
        return res[0]
    }
    else if (wallet === "metamask") {
        try {
            let res = await window.ethereum.request({ method: 'eth_requestAccounts' })
            return res[0]
        }
        catch (err) {
            console.log("err", err)
        }
    }

}



