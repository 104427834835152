import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../Components/Misc/Button";
import { getItemDetails } from "../Services/apiServices";
import Loader from "./../Components/Common/loader"
import Popup from "../Components/Misc/popup";
// import eventEmitter from "./events/events";
function ItemDetails() {
  const { chain_id, pid, collectionAddress } = useParams();
  const [product, setProduct] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [status, setStatus] = useState("");
  const [color, setColor] = useState("");
  const [loader, setLoader] = useState(false);
  const [ProductDesc, setProductDesc] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      try {
        let data = {
          contract_address: collectionAddress,
          product_id: pid,
          chain_id: chain_id,
        };
        const res = await getItemDetails(data);
        if (res) setProduct(res);
        if (
          (res?.start_date,
            res?.end_date,
            Number(res?.start_date) < Math.round(new Date().valueOf() / 1000) &&
            Number(res?.end_date) > Math.round(new Date().valueOf() / 1000))
        ) {
          setProductDesc(res.description);
          setStatus("Active");
          setColor("#3BE211");
        } else if (
          Number(res?.start_date) < Math.round(new Date().valueOf() / 1000) &&
          Number(res?.end_date) < Math.round(new Date().valueOf() / 1000)
        ) {
          setStatus("Expired");
          setColor("#8A959E");
        } else if (
          Number(res?.start_date) > Math.round(new Date().valueOf() / 1000)
        ) {
          setStatus("Upcoming");
          setColor("#E2B411");
        } else {
          setStatus("");
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    };
    fetch();
  }, [pid, collectionAddress, chain_id]);
  // useEffect(() => {
  //   const fetch = async () => {
  //     setLoader(true);
  //     try {
  //       const res = await getItemDetails(collectionAddress, pid);
  //       console.log("response", res);
  //       if (res && res.length > 0) setProduct(res[0]);
  //       setProductDesc(res[0].productDesc);
  //       if (
  //         (res[0].start_date,
  //         res[0].end_date,
  //         Number(res[0].start_date) < Math.round(new Date().valueOf() / 1000) &&
  //           Number(res[0].end_date) > Math.round(new Date().valueOf() / 1000))
  //       ) {
  //         setStatus("Active");
  //         setColor("#3BE211");
  //       } else if (
  //         Number(res[0].start_date) < Math.round(new Date().valueOf() / 1000) &&
  //         Number(res[0].end_date) < Math.round(new Date().valueOf() / 1000)
  //       ) {
  //         setStatus("Expired");
  //         setColor("#8A959E");
  //       } else if (
  //         Number(res[0].start_date) > Math.round(new Date().valueOf() / 1000)
  //       ) {
  //         setStatus("Upcoming");
  //         setColor("#E2B411");
  //       } else {
  //         setStatus("");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //     setLoader(false);
  //   };
  //   fetch();
  // }, [pid, collectionAddress]);
  const hideModal = (data) => {
    setShow(data);
  };
  return (
    <>
      {loader && <Loader />}
      {show && (
        <Popup
          productDescription={ProductDesc}
          show={show}
          hideFunc={hideModal}
        />
      )}

      <div className="container item-container item_details_div">
        {/* <div className="row"><a href={`/redeem/${location?.state?.collection}`}>Back</a></div> */}
        <div className="row gy-3 item-row align-items-center">
          <div className="col-lg-6">
            <div className="item_detail_img_div d-flex justify-content-center align-items-center">
              <img
                className="item-img"
                alt="itemImage"
                src={product?.image_url}
              ></img>
            </div>
          </div>
          <div className="col-lg-6 detail-container">
            <div className="w-100 d-flex justify-content-end align-items-center">
              {status !== "" && (
                <>
                  <div
                    className="statusColor"
                    style={{ background: color }}
                  ></div>
                  <p className="statusText">{status}</p>
                </>
              )}
            </div>
            <div className="upper_half">
              <h3 className="product-name">{product.name}</h3>
              {ProductDesc.length > 80 ? (
                <p id="long-text">
                  {ProductDesc.substring(0, 81) + "..."}{" "}
                  <button
                    type="submit"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Read More
                  </button>
                </p>
              ) : (
                <p>{ProductDesc}</p>
              )}
            </div>
            <div className="lower_half mt-3">
              <h3 className="product-name">Details</h3>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Item Id </p>
                <p className="right"># {product.product_id}</p>
              </div>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Collection Name </p>
                <p className="right">
                  {product?.collectionData?.length > 0
                    ? product?.collectionData[0]?.name
                    : "-"}
                </p>
              </div>
              <div className="w-100 d-flex justify-content-between">
                <p className="left">Collection Address</p>
                <p className="right">
                  {product?.contract_address?.slice(0, 4) +
                    "..." +
                    product?.contract_address?.slice(38, 42)}
                </p>
              </div>
            </div>
            {/* <div className="my-4 d-flex justify-content-center">
              <Button
                buttonClass="main_btn px-5"
                onClick={() => {
                  window.location.href = "/RedeemPortal";
                }}
              >
                Redeem Now
              </Button>
            </div> */}
          </div>
          <div className="col-12 notes_div">
            <div className="w-100 d-flex justify-content-start align-items-center my-3">
              <div className="notes"></div>
              <h3 className="product-name">Notes</h3>
            </div>
            <p>
              {!showMore ? (
                <p className="text-start">
                  {product?.notes
                    ? product?.notes?.slice(0, 120) +
                    (product?.notes?.length > 120 ? "..." : "")
                    : "-"}
                </p>
              ) : (
                <p className="text-start">
                  {" "}
                  {product?.notes ? product?.notes : "-"}
                </p>
              )}
              {product?.notes?.length > 120 ? (
                <div className="showmore"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? "Show More" : "Show Less"}
                </div>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemDetails;
