import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import toast from "react-hot-toast";
import logo from "../../Assets/images/footerLogo.png";
import { subscribeMail } from "../../Services/apiServices";
export default function Footer() {
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState();
  const [disabledSubscribe, setDisabledSubscribe] = useState();
  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const subscribeHandler = async () => {
    setLoader(true);
    setDisabledSubscribe(true);
    let regx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!regx.test(email)) {
      setTimeout(() => {
        toast.error("email not valid!");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 500);
      return;
    }
    let emailAddress = { email };
    const res = await subscribeMail(emailAddress);
    console.log(res);
    if (res.statusCode === 200) {
      setEmail("");
      setTimeout(() => {
        toast.success("Successfully Subscribed");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 1000);

      // navigate("/");
    } else if (res.statusCode === 409) {
      setTimeout(() => {
        toast.error("Email Already Exist!");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 1000);
    } else if (res.statusCode === 406) {
      setTimeout(() => {
        toast.error("Email Not Valid! Please Enter Valid Email Address");
        setLoader(false);
        setDisabledSubscribe(false);
      }, 1000);
    }
  };
  // const EnableAddDesignation = () => {
  //   setDisabledAddDesignation(false);
  // };
  return (
    <>
      <footer>
        <div className="container mt-5 py-3">
          <div className="footer_wrapper">
            <div className="row footer_row gy-3">
              <div className="col-md-6 col-lg-4">
                <div className="w-100 h-100 footer_logo d-flex p-5 ps-md-0 justify-content-center align-items-center">
                  <img src={logo} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 ps-5">
                <div>
                  <h3>Explore</h3>
                  <ul>
                    <li className="footer_links">
                      <a href="/contact-us">Contact Us</a>
                    </li>
                    <li className="footer_links">
                      <a href="/#">Policy</a>
                    </li>
                    <li className="footer_links">
                      <a href="/#">Clients</a>
                    </li>
                    <li className="footer_links">
                      <a href="/faq">FAQ</a>
                    </li>
                    <li className="footer_links">
                      <a href="/#">Partners</a>
                    </li>
                    <li className="footer_links">
                      <a href="/#">T&C</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 text-start">
                <h3 className="mb-3">Subscribe Now !</h3>
                <p className="text-start">
                  Stay updated on new supported collection and claimable items
                </p>
                <div className="text-center d-flex flex-wrap justify-content-start w-100 align-items-center sub_div">
                  <input
                    type="text"
                    value={email}
                    placeholder="Email Address"
                    onChange={emailHandler}
                    disabled={disabledSubscribe}
                  />
                  <button
                    id="subscribe"
                    type="submit"
                    className={loader ? "sub_btn set_padding" : "sub_btn"}
                    onClick={subscribeHandler}
                    disabled={disabledSubscribe}
                  >
                    Subscribe
                    {loader ? (
                      <ColorRing
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="footer_copyright">
              <p className="mt-3 mb-0">
                Decrypt Block Copyright 2023 @All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
