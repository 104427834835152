/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { getItemsByStatus, viewItems } from "../Services/apiServices";
import { PAGE_LIMIT_PRODUCT, PLACEHOLDER } from "../Helpers/constant";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { useParams } from "react-router-dom";
import Loader from "./../Components/Common/loader"

function Items() {
  const [allItems, setAllItems] = useState("none");
  const [allCount, setAllCount] = useState(0);
  const [count, setCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const { searchedText } = useParams();
  const [status, setStatus] = useState("Active");
  const [items, setItems] = useState("none");
  const [iCurrPage, setICurrPage] = useState(1);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      try {
        let reqbody = {
          page: currPage,
          limit: PAGE_LIMIT_PRODUCT,
          isBlocked: false,
          isImported: true,
          productName: searchedText,
        };
        const res = await viewItems(reqbody);
        setAllCount(res?.count);
        console.log("res===>", res, res?.results[0]);
        if (res?.results && res?.results?.length > 0) setAllItems(res?.results);
        else setAllItems([]);
      } catch (error) {
        console.log("Error in getCollection", error);
      }
      setLoader(false);
    };
    fetch();
  }, [currPage]);

  useEffect(() => {
    handleStatusTabChange(status);
  }, [iCurrPage]);

  const handleStatusTabChange = async (_status) => {
    try {
      let reqBody = {
        page: iCurrPage,
        limit: PAGE_LIMIT_PRODUCT,
        isImported: true,
        isBlocked: false,
        status: _status,
        productName: searchedText,
      };
      const itemList = await getItemsByStatus(reqBody);
      console.log("itemList ------->", itemList);
      setCount(itemList?.count);
      if (itemList?.results && itemList?.results?.length > 0)
        setItems(itemList?.results);
      else setItems([]);
    } catch (error) {
      console.log("Error in handleStatusTabChange", error);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <section className="Banner_section">
        <div className="container ">
          <div className="banner_container">
            <h2>Items</h2>
          </div>
        </div>
      </section>

      <div className="tabs_div mt-5">
        <div className="container">
          <ul
            className="nav tabs_ul justify-content-around nav-pills mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tabs_btn active"
                id="pills-active-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-active"
                type="button"
                role="tab"
                aria-controls="pills-active"
                aria-selected="true"
                onClick={() => {
                  setStatus("Active");
                  setItems("none");
                  setICurrPage(1);
                  handleStatusTabChange("Active");
                }}
              >
                <div className="activeTab"></div> Active
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tabs_btn"
                id="pills-future-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-future"
                type="button"
                role="tab"
                aria-controls="pills-future"
                aria-selected="false"
                onClick={() => {
                  setStatus("Upcoming");
                  setItems("none");
                  setICurrPage(1);
                  handleStatusTabChange("Upcoming");
                }}
              >
                <div className="futureTab"></div> Future
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tabs_btn"
                id="pills-expired-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-expired"
                type="button"
                role="tab"
                aria-controls="pills-expired"
                aria-selected="false"
                onClick={() => {
                  setStatus("Expired");
                  setItems("none");
                  setICurrPage(1);
                  handleStatusTabChange("Expired");
                }}
              >
                <div className="expiredTab"></div> Expired
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tabs_btn"
                id="pills-all-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-all"
                type="button"
                role="tab"
                aria-controls="pills-all"
                aria-selected="false"
              >
                {" "}
                All
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-active"
              role="tabpanel"
              aria-labelledby="pills-active-tab"
            >
              <section className="recent_items items_page_cards">
                <div className="container text-center">
                  {items !== "none" ? (
                    <>
                      <div className="row">
                        {items?.length > 0 &&
                          items?.map((data, index) => {
                            return (
                              <a
                                href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
                                key={index}
                              >
                                <div className="custom_card">
                                  <div className="collection_img">
                                    <a
                                      href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                    >
                                      {console.log("data--------->", data)}
                                      <img src={PLACEHOLDER} onLoad={(e) => {
                                        e.target.src = data?.low_image ? data?.low_image : PLACEHOLDER

                                      }} alt="collection" />
                                    </a>
                                    <p># {data?.productId}</p>
                                  </div>
                                  <div className="content">
                                    <h5>{data.productName}</h5>
                                    <p>
                                      {data?.productDesc?.length > 20
                                        ? data?.productDesc.slice(0, 20) + "..."
                                        : data.productDesc}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            );
                          })}
                      </div>
                      {items?.length > 0 &&
                        Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
                          <Pagination
                            {...bootstrap5PaginationPreset}
                            current={iCurrPage}
                            total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
                            onPageChange={(p) => setICurrPage(p)}
                          />
                        )}
                      {items?.length === 0 && (
                        <span variant="lg" className="NoData">
                          No Result Found
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>

            <div
              className="tab-pane fade"
              id="pills-future"
              role="tabpanel"
              aria-labelledby="pills-future-tab"
            >
              <section className="recent_items items_page_cards">
                <div className="container text-center">
                  {items !== "none" ? (
                    <>
                      <div className="row">
                        {items?.length > 0 &&
                          items?.map((data, index) => {
                            return (
                              <a
                                href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
                                key={index}
                              >
                                <div className="custom_card">
                                  <div className="collection_img">
                                    <a
                                      href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                    >
                                      <img src={PLACEHOLDER} onLoad={(e) => {
                                        e.target.src = data?.low_image ? data?.low_image : PLACEHOLDER

                                      }} alt="collection" />
                                    </a>
                                    <p># {data?.productId}</p>
                                  </div>
                                  <div className="content">
                                    <h5>{data.productName}</h5>
                                    <p>
                                      {data?.productDesc?.length > 20
                                        ? data?.productDesc.slice(0, 20) + "..."
                                        : data.productDesc}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            );
                          })}
                      </div>
                      {items?.length > 0 &&
                        Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
                          <Pagination
                            {...bootstrap5PaginationPreset}
                            current={iCurrPage}
                            total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
                            onPageChange={(p) => setICurrPage(p)}
                          />
                        )}
                      {items?.length === 0 && (
                        <span variant="lg" className="NoData">
                          No Result Found
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>

            <div
              className="tab-pane fade"
              id="pills-expired"
              role="tabpanel"
              aria-labelledby="pills-expired-tab"
            >
              <section className="recent_items items_page_cards">
                <div className="container text-center">
                  {items !== "none" ? (
                    <>
                      <div className="row">
                        {items?.length > 0 &&
                          items?.map((data, index) => {
                            return (
                              <a
                                href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3 item-anchor-tag"
                                key={index}
                              >
                                <div className="custom_card">
                                  <div className="collection_img">
                                    <a
                                      href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                    >
                                      <img src={PLACEHOLDER} onLoad={(e) => {
                                        e.target.src = data?.low_image ? data?.low_image : PLACEHOLDER

                                      }} alt="collection" />
                                    </a>
                                    <p># {data?.productId}</p>
                                  </div>
                                  <div className="content">
                                    <h5>{data.productName}</h5>
                                    <p>
                                      {data?.productDesc?.length > 20
                                        ? data?.productDesc.slice(0, 20) + "..."
                                        : data.productDesc}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            );
                          })}
                      </div>
                      {items?.length > 0 &&
                        Math.ceil(count / PAGE_LIMIT_PRODUCT) > 1 && (
                          <Pagination
                            {...bootstrap5PaginationPreset}
                            current={iCurrPage}
                            total={Math.ceil(count / PAGE_LIMIT_PRODUCT)}
                            onPageChange={(p) => setICurrPage(p)}
                          />
                        )}
                      {items?.length === 0 && (
                        <span variant="lg" className="NoData">
                          No Result Found
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>

            <div
              className="tab-pane fade"
              id="pills-all"
              role="tabpanel"
              aria-labelledby="pills-all-tab"
            >
              <section className="recent_items items_page_cards">
                <div className="container text-center">
                  {allItems !== "none" ? (
                    <>
                      <div className="row">
                        {allItems?.length > 0 &&
                          allItems?.map((data, index) => {
                            console.log(
                              "data in all items",
                              data?.collectionData[0]?.chainId
                            );
                            console.log(
                              "Collection Data----->",
                              data?.collectionData[0]
                            );
                            return (
                              <a
                                href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12 p-3"
                                key={index}
                              >
                                <div className="custom_card">
                                  <div className="collection_img">
                                    <a
                                      href={`/item-details/${data?.collectionData[0]?.chainId}/${data?.collectionAddress}/${data?.productId}`}
                                    >
                                      <img src={PLACEHOLDER} onLoad={(e) => {
                                        e.target.src = data?.low_image ? data?.low_image : PLACEHOLDER

                                      }} alt="collection" />
                                    </a>
                                    <p># {data?.productId}</p>
                                  </div>
                                  <div className="content">
                                    <h5>{data.productName}</h5>
                                    <p>
                                      {data?.productDesc?.length > 20
                                        ? data?.productDesc.slice(0, 20) + "..."
                                        : data.productDesc}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            );
                          })}
                      </div>
                      {allItems?.length > 0 &&
                        Math.ceil(allCount / PAGE_LIMIT_PRODUCT) > 1 && (
                          <Pagination
                            {...bootstrap5PaginationPreset}
                            current={currPage}
                            total={Math.ceil(allCount / PAGE_LIMIT_PRODUCT)}
                            onPageChange={(p) => setCurrPage(p)}
                          />
                        )}
                      {allItems?.length === 0 && (
                        <span variant="lg" className="NoData">
                          No Result Found
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Items;
