import React from 'react'

export default function RewardsBanner({Heading_titile}) {
  return (<>
        <section className='Banner_section'>
            <div className='container '>
                <div className='banner_container'>
                    <h2>{Heading_titile}</h2>
                </div>
            </div>
        </section>
  </>)
}
