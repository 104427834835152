import React from "react";
import { ProgressBar } from "react-loader-spinner";

function PercentLoader() {
	return (
	
			<ProgressBar
				height="100"
				width="100"
				ariaLabel="progress-bar-loading"
				wrapperStyle={{}}
				wrapperClass="progress-bar-wrapper"
				borderColor="#ff3366"
				barColor="#2f7bbd"
			/>
			
		
	);
}

export default PercentLoader;
